import React, {useEffect, useMemo, useRef, useState} from 'react';
import axios from 'axios';
import {Helmet} from "react-helmet";
import Search from './../../components/search';
import Headlines from './../../components/headlines';
import classNames from './../../helpers/classnames';
import StackedBar from './../../components/charts/stacked-bar'
import Bar from './../../components/charts/bar'
import Pie from './../../components/charts/pie'
import Loader from './../../components/common/loader';
import {getSearchEntries} from './../../helpers/search-helpers';
import {
  getAreaIdTypeInfo,
  getChartTimestamp,
  getElectionLocation,
  getInfoByAreaId,
  getVoteCastPercentage,
  isKretArea,
  isMandatesApplicable,
} from './../../helpers/charts-helper';
import {ChartsData} from './../../helpers/charts-class';

import {sanitizeMarkup} from './../../helpers/sanitizers';
import {ConfigsContext} from '../../context';
import HistoricalDataChart from "../../components/sections/historicalDataChart";

function emptyChartsData() {
  return ({
    antallsb: '', // number of sb
    frammote: {},
    id: {},
    mandater: {}, // mandates
    merknader: [], // notes
    opptalt: {}, // enumerated
    partier: [], // parties
    prognose: {}, // forecast
    stemmegiving: {}, // voting
    stemmer: {}, // votes
    tidspunkt: {}, // point
    _links: {},
  })
}

const electionYear = process.env.ELECTION_YEAR;


const App = (props) => {
  const configs = props.configs;

  const domainUrl = configs.domainUrl;

  const initialActiveTab = configs.valgSwitch || "KO";
  const initialAreaId = configs.areaId || '00';
  const areaInfo = getInfoByAreaId(initialAreaId, initialActiveTab);

  const [activeTab, setActiveTab] = useState(areaInfo.activeTab);
  const [areaId, setAreaId] = useState(areaInfo.areaId); // the json file name in '2023-{valgswitch}-xx` or `2023-{valgswitch}-xx`
  const [searchItems, setSearchItems] = useState([]);
  const [refreshRate, setRefreshRate] = useState(configs.refreshRate ? Number(configs.refreshRate) : 5); // Five minutes
  const [loadingAreaData, setLoadingAreaData] = useState(false);
  const [chartsData, setChartsData] = useState(emptyChartsData())
  const [tickerData, setTickerData] = useState({})

  const dataRefresherRef = useRef(null);

  const chartsDataUrl = domainUrl + `/json/${electionYear}`
  const tickerDataUrl = domainUrl + `/robot/${electionYear}`;

  // http://localhost:8080/?areaId=03-0301&&valgSwitch=KO&searchbox=false&navigationTab=false&headline=false&locationName=false&timestamp=false&stackedBar=false&percentageChartText=false&percentageChart=false&percentageChartLabel=false&mandatesChartText=false&mandatesChart=false&mandatesChartLabel=false&article=false
  // console.log("Url params ", qs.parse(window.location.search, { ignoreQueryPrefix: true }));

  const getAreaDataFileName = () => {
    let fileName = `2023-${activeTab}-${areaId}.json`

    if (areaId === '00' || !areaId) {
      fileName = `2023-${activeTab}.json`
    }

    const areaIdParts = areaId.split('-')
    if (areaIdParts[0] === '00' && areaIdParts[1]) {
      setActiveTab(areaIdParts[1]);
      fileName = `2023-${areaIdParts[1]}.json`
    }

    return fileName;
  }

  const loadChartsData = () => {
    const fileName = getAreaDataFileName();
    return axios.get(`${chartsDataUrl}/${fileName}`, {params: {t: new Date().getTime()}})
      .then(res => {
        return res.data;
      })
      .catch(error => {
        return undefined;
      });
  }

  const loadTickerData = () => {
    const fileName = getAreaDataFileName();
    return axios.get(`${tickerDataUrl}/${fileName}`, {params: {t: new Date().getTime()}})
      .then(res => {
        return res.data;
      })
      .catch(error => {
        return undefined;
      });
  }

  const loadAreaData = async () => {
    let [chartsData, tickerData] = await Promise.all([loadChartsData(), loadTickerData()]);

    chartsData = chartsData || emptyChartsData();

    if (chartsData && isKretArea(areaId) && chartsData.id?.navn?.toUpperCase() === 'UOPPGITT KRETS') {
      const matchedArea = searchItems.find(item => item.filePostfix === areaId);
      chartsData.id.navn = matchedArea?.title || chartsData.id.navn;
    }

    setLoadingAreaData(false);
    setChartsData(chartsData);
    setTickerData(tickerData);
  }

  const changeAreaId = (areaId) => {
    const areaInfo = getAreaIdTypeInfo(areaId, activeTab);

    setActiveTab(areaInfo.activeTab);
    setAreaId(areaInfo.areaId);
    setLoadingAreaData(true);
  }

  const getAreaList = () => {
    const fileName = 'GSK-KO-2023.json';

    const url = domainUrl + `/json/${electionYear}`

    return axios.get(`${url}/${fileName}`)
      .then(res => {
        const data = res.data;
        const searchItems = getSearchEntries(data)
          // Remove Oslo county from this local election because it has no data
          .filter(entry => entry.filePostfix !== "03" && entry.title !== "Oslo");

        setSearchItems(searchItems);
      })
      .catch(error => {
        return [];
      });
  }

  const renderCss = () => {
    const {css} = configs;

    return (
      css ? (
        <Helmet>
          <link rel="stylesheet" href={`${configs.styleSheetUrl}/${css}.css`} media="screen"/>
        </Helmet>
      ) : null
    );
  }

  useEffect(() => {
    // const searchItems = getSearchEntries(gskMunicipalities);
    // setSearchItems(searchItems);
    setLoadingAreaData(true);
    getAreaList();
  }, []);

  useEffect(() => {
    if (searchItems.length > 0) {
      loadAreaData();
    }
  }, [searchItems])


  useEffect(() => {
    if (dataRefresherRef.current) {
      clearInterval(dataRefresherRef.current);
    }

    dataRefresherRef.current = setInterval(() => {
      loadAreaData();
      return () => {
        clearInterval(dataRefresherRef.current);
      }
    }, refreshRate * 60 * 1000);
  }, [activeTab, areaId, refreshRate]);

  useEffect(() => {
    if (loadingAreaData) {
      loadAreaData();
    }
  }, [areaId, loadingAreaData, activeTab])

  const {barChartHistoryData, barChartCurrentData, mandatesChartData} = useMemo(() => {
    const chart = new ChartsData(chartsData);
    return chart.getChartsData();
  }, [chartsData]);

  const mandatesAvailable = isMandatesApplicable(chartsData);

  // const [showTestDataWarning, setShowTestDataWarning] = useState(false)
  //
  // useEffect(() => {
  //   const now = (new Date()).getTime()
  //
  //   // 1694458740 = Mon Sep 11 2023 20:59:00 GMT+0200 (Central European Summer Time)
  //   // Use this website to get the correct unix timestamp: https://www.unixtimestamp.com/
  //   // Remember to enter your local time, not the UTC time
  //   const realDataStart = 1694458740 * 1000
  //
  //   if (now < realDataStart) {
  //     setShowTestDataWarning(true)
  //   }
  // }, []);

  return (
    <ConfigsContext.Provider value={configs}>
      <div className={classNames('container')}>
        <div className={classNames('header')}>
          {renderCss()}
          {configs.searchbox !== 'false' && (
            <Search title={`Søk etter fylke, kommune eller krets`} searchItems={searchItems} areaId={areaId}
                    onChangeAreaId={changeAreaId}/>
          )}

          {/*{showTestDataWarning && <div className={classNames('test-data-warning')}>*/}
          {/*  <div className={classNames('test-data-warning__text')}>*/}
          {/*    Frem til valgkvelden 11. september <strong>kl. 21.00</strong> er resultatene basert på simuleringer kun for testing.*/}
          {/*  </div>*/}
          {/*</div>}*/}

          <Headlines
            location={configs.locationName !== 'false' ? getElectionLocation(chartsData) : ''}
            timestamp={configs.timestamp !== 'false' ? getChartTimestamp(chartsData) : ''}
            voteCountPercentage={getVoteCastPercentage(chartsData)}
          />

          {configs.stackedBar !== 'false' && barChartCurrentData.every(x => x.stemmer !== 0) && (
            <StackedBar
              data={barChartCurrentData}
            />
          )}

          <div className={classNames('displayFlex')}>
            {configs.percentageChart !== 'false' && (
              <div style={{minWidth: "360px", paddingTop: mandatesAvailable && mandatesChartData && mandatesChartData.length > 0 ? "30px" : null}}>
                <Bar
                  historyData={barChartHistoryData}
                  currentData={barChartCurrentData}
                  historicHoverText={'De lyse søylene viser resultatet i 2019'}
                />
              </div>
            )}

            {configs.mandatesChart !== 'false' && mandatesAvailable && mandatesChartData && mandatesChartData.length > 0 && (
              <div style={{minWidth: "360px", paddingTop: configs.percentageChart !== 'false' ? "50px" : "30px"}}>
                <Pie
                  data={mandatesChartData}
                  valgSwitch={activeTab}
                  areaId={areaId}
                />
              </div>
            )}
          </div>

          {(tickerData?.historic && configs.historic !== 'false') && <HistoricalDataChart data={tickerData?.historic} valgSwitch={activeTab} />}

          {tickerData?.body && configs.article !== 'false' && (
            <div className={classNames('ticker')}>
              <div className={classNames('ticker__contentContainer')}>
                <div dangerouslySetInnerHTML={{__html: sanitizeMarkup(tickerData.body)}}/>
                {/*<h2 className={classNames('ticker__title')}>Brakvalg for Senterpartiet</h2>*/}
                {/*<h4 className={classNames('ticker__byline')}>Fire mandater i Hordaland sikrer flertall for regjeringsskifte. Kjersti Toppe er sikret gjenvalg og får med seg tre ferske fjes fra inn i Sps stortingsgruppe fra valgdistriktet.</h4>*/}
                {/*<p>Senterpartiet (14,9 prosent) har størst framgang i oppslutning fra stortingsvalget i 2017 (11,2 prosentpoeng) og sikrer ett nytt mandat (får nå ett mandat på Stortinget). Så langt er 42,3 prosent av stemmene opptelt, hvorav de fleste er forhåndsstemmer.</p>*/}
                {/*<p>Arbeiderpartiet får en oppslutning på 31 prosent (tilbake 8,8 prosentpoeng) og er største parti i fylket. Partiet får to mandater (ingen endring). </p>*/}
                {/*<p>Fremskrittspartiet får nest flest stemmer med en oppslutning på 19,7 prosent (fram 3,7 prosentpoeng). Det gir ett mandat (uendret).</p>*/}
                {/*<p>Tredje største parti i Hordaland er Senterpartiet.</p>*/}
                {/*<p>Det siste mandatet går til Bengt Rune Strifeldt (Fremskrittspartiet). Mandatfordeling: Ap 2 (uendret), Sp 1 (+1), H 1 (uendret), Frp 1 (uendret), SV 0 (-1).</p>*/}
                {/*<p>(©NTB)</p>*/}
              </div>
            </div>
          )}

          <Loader loading={loadingAreaData}/>
        </div>
      </div>
    </ConfigsContext.Provider>
  );
}

export default App;