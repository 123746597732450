import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import nb from './i18n/nb.json'
import nn from './i18n/nn.json'

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      nb,
      nn
    },
    lng: "nb",
    fallbackLng: "nb",

    interpolation: {
      escapeValue: false
    }
  });

  export default i18n;