import { maxBy, orderBy, get } from 'lodash-es';
import parliamentMembers from './../data/candidates.json'
import { calculateAge } from './utils';
import i18n from '../i18n';
// import districtsData from './../../election-data/json/2021/2021-ST-fylker-aggregate.json'

export const PRIORITIZED_PARTY_KODES = ['R', 'SV', 'AP', 'SP', 'V', 'KRF', 'H', 'FRP', 'MDG'];
export const PARTY_CODES_IN_ORDER = ['R', 'SV', 'AP', 'SP', 'MDG', 'V', 'KRF', 'H', 'FRP', 'And'];
export const MAIN_PARTY_CODES = ['R', 'SV', 'AP', 'SP', 'MDG', 'V', 'KRF', 'H', 'FRP'];

// export function isIgnorePartyResult(partyCode) {
//   return partyCode == "Andre" || partyCode == "Andre2" || partyCode == "BLANKE";
// }

export function getRoundedPercentage(percentage) {
  return Math.round(percentage * 10) / 10;
}

export function getMaxVoteAndrePercentage(partiesData) {
  let maxPercentageIndex = -1;
  let maxPercentageValue = -1;
  let party;

  partiesData.forEach((partyData, key) => {
    if (partyData.parti != "A" && PRIORITIZED_PARTY_KODES.indexOf(partyData.parti) === -1 && partyData.mandater > 0 && partyData.stemmer > 0 && partyData.stemmer > maxPercentageValue) {
      // console.log("THe parti code ", partyData.parti)
      maxPercentageValue = partyData.stemmer
      maxPercentageIndex = key;
      party = { ...partyData };
    }
  });

  if (!party) {
    maxPercentageIndex = -1;
    maxPercentageValue = -1;

    // let votePercentage = 0;
    partiesData.forEach((partyData, key) => {

      // if (partyData.parti != "A" && PRIORITIZED_PARTY_KODES.indexOf(partyData.parti) === -1) {
      //   votePercentage += partyData.stemmer;
      //   console.log("THe parti code ", partyData.parti, " Vote percentage ", partyData.stemmer)
      // }
      
      if (partyData.parti != "A" && PRIORITIZED_PARTY_KODES.indexOf(partyData.parti) === -1  && partyData.stemmer > 0 && partyData.stemmer > maxPercentageValue) {
        maxPercentageValue = partyData.stemmer
        maxPercentageIndex = key;
        party = { ...partyData };
      }
    });

    // console.log("Total vote percentage ", (votePercentage - 1.75682))
  }

  return {
    party: party,
    percentage: maxPercentageValue,
    index: maxPercentageIndex,
  }
}

export function filterPartiesData(partiesData) {
  //////////// Filter data for prioritized parties
  const filteredPartiesData = partiesData.filter(function (r) { return (r.stemmer >= 0 && PRIORITIZED_PARTY_KODES.indexOf(r.parti) > -1) });

  if (filteredPartiesData.length > 0) {
    partiesData = filteredPartiesData;
  } else {
    // If each of parioritized parties have no positive vote percentage, include only prioritized parties vote data
    if (partiesData.filter(function (ele) { return ele.stemmer > 0 }).length == 0) {
      partiesData = partiesData.filter(function (r) { return PRIORITIZED_PARTY_KODES.indexOf(r.parti) > -1 })
    }
  }

  return partiesData;
}

export function otherPariesPercentageData(partiesData, filteredParitesData) {
  const otherPartiesData = [];

  const currentPartyAIndex = getIndexOfPartyWithCodeA(filteredParitesData);

  if (currentPartyAIndex > -1) {
    otherPartiesData.push({ ...filteredParitesData[currentPartyAIndex] });
  }

  for (const partyData of partiesData) {
    if ((partyData.stemmer < 1 || PRIORITIZED_PARTY_KODES.indexOf(partyData.parti) == -1) && partyData.parti != "A") {
      otherPartiesData.push({ ...partyData });
    }
  }

  return otherPartiesData;
}

export function getIndexOfPartyWithCodeA(partiesData) {
  return findArrayIndex(partiesData, function (d) {
    return d.parti == "A";
  });
}

function gePartyWithCodeA(partiesData) {
  return partiesData.find(partyData => partyData.parti == "A")
}

export function getPartisDataVotePercentage(partiesData) {
  let votePercentage = 0;

  for (const partyData of partiesData) {
    votePercentage += partyData.stemmer;
  }

  return votePercentage;
}

export function getPartiesDataMandates(partiesData) {
  let mandates = 0;

  for (const partyData of partiesData) {
    mandates += (partyData.mandater || partyData.value || 0);
  }

  return mandates;
}

export function isMandatesApplicable(data) {
  const totalVotes = getVoteCastPercentage(data)

  const totalMandates = data.mandater.antall || 0;
  return totalMandates > 0 && totalVotes > 0
}

export function getChartTimestamp(data) {
  // There will be settings to show/hide for this

  const voteCastPercentage = getVoteCastPercentage(data);

  let timestamp;

  let mytime = !!data.tidspunkt.rapportGenerert ? data.tidspunkt.rapportGenerert : '';
  mytime = mytime.split('T');
  let myDate = mytime[0] || '';
  let myDateParts = myDate.split('-');
  let myDay = myDateParts[2] || ''
  let myMonth = myDateParts[1] || ''
  let myYear = myDateParts[0] || ''
  mytime = !!mytime[1] ? mytime[1].split(':') : '';

  if (typeof mytime !== 'string' && voteCastPercentage < 100) {
    if (myDateParts.length === 3) {
      timestamp = "KL. " + mytime[0] + "." + mytime[1] + " " + myDay + "." + myMonth + "." + myYear;
    } else {
      timestamp = "KL. " + mytime[0] + "." + mytime[1];
    }
  }
  return timestamp;
}

export function getPercentageText(data) {
  // There will be settings to show/hide for this
  const voteCastPercentage = getVoteCastPercentage(data);

  return (voteCastPercentage < 100 ? ', ' : '') + ' ' + voteCastPercentage + '% AV STEMMENE OPPTELT';
}

export function getBarChartTopText(activeTab, areaId, data) {
  const partyName = getHighestPercentagePartyName(data);
  const resultBasisLabel = getResultBasisLabel(data);

  let councilLabel = '';
  let partyOrVoteWiseLabel = '';

  switch (activeTab) {
    case 'KO':
      if (areaId == '00') {
        partyOrVoteWiseLabel = 'er største parti i';
        councilLabel = 'kommunestyrene';
      }
      else {
        partyOrVoteWiseLabel = 'har flest stemmer ved';
        councilLabel = 'kommunevalget';
      }
      break;
    case 'FY':
      if (areaId == '00') {
        partyOrVoteWiseLabel = 'er største parti i';
        councilLabel = 'fylkestingene';
      } else {
        partyOrVoteWiseLabel = 'har flest stemmer ved';
        councilLabel = 'fylkestingvalget';
      }
      break;
    case 'RST':
      if (areaId == '00') {
        partyOrVoteWiseLabel = 'er største parti i';
        councilLabel = 'stortingsvalget';
      } else {
        partyOrVoteWiseLabel = 'fikk flest stemmer ved';
        councilLabel = 'stortingsvalget';
      }
      break;
  }

  const completeText = `{{ partyName }} ${partyOrVoteWiseLabel} ${councilLabel}${resultBasisLabel}`
  return i18n.t(`charts:percentages:${completeText}`, { partyName: partyName });
}

export function getPieChartTopText(activeTab, areaId, data) {
  const partyName = getHigestMandatPartyName(data);
  const resultBasisLabel = getResultBasisLabel(data);
  const isDistrict = isElectionDistrict(data);

  let councilLabel = '';
  let partyOrRepresentativeWiseLabel = '';
  let districtName = '';

  const isAreaIdKretsOrMunicipality = isKretsOrMunicipality(areaId)

  switch (activeTab) {
    case 'KO':
      if (!isAreaIdKretsOrMunicipality) {
        partyOrRepresentativeWiseLabel = 'har flest representanter i';
        councilLabel = 'kommunestyrene';
      }
      else {
        partyOrRepresentativeWiseLabel = 'er største parti i';
        councilLabel = 'kommunestyret';
      }
      break;
    case 'FY':
      if (!isAreaIdKretsOrMunicipality) {
        partyOrRepresentativeWiseLabel = 'har flest representanter i';
        councilLabel = 'fylkestingene';
      } else {
        partyOrRepresentativeWiseLabel = 'er største parti i';
        councilLabel = 'fylkestinget';
      }
      break;
    case 'RST':
      // For municipality and krets, there will be no mandates(pie) chart
      if (areaId == '00') {
        partyOrRepresentativeWiseLabel = 'fikk flest mandater i';
        councilLabel = 'Norge';
      } else {
        partyOrRepresentativeWiseLabel = 'fikk flest mandater i';
        districtName = getElectionDistrictName(data);
        councilLabel = isDistrict ? `{{ districtName }} valgdistrikt` : 'Norge';
      }
      break;
  }

  const completeText = `{{ partyName }} ${partyOrRepresentativeWiseLabel} ${councilLabel}${resultBasisLabel}`;
  return i18n.t(`charts:mandates:${completeText}`, { partyName: partyName, districtName: districtName });
}

function getResultBasisLabel(data) {
  let label = '';

  if (data.opptalt && data.opptalt.forelopigVts == 0) {
    label = " (basert på forhåndstemmer)";
  }

  if (data.prognose && data.prognose.beregnet == true) {
    label = " (basert på prognose)"
  }

  return label;
}

export function getVoteCastPercentage(data) {
  let voteCastPercentage = 0;
  if (data.opptalt) {
    voteCastPercentage = data.opptalt.prosent

    if (!(voteCastPercentage < 100)) {
      voteCastPercentage = 100;
    }
  }

  return voteCastPercentage;
}

export function getHighestPercentagePartyName(data) {
  let paryName = '';
  let highestPercentage = 0;

  for (const party of data.partier) {
    const partyCode = getPartyCode(party);
    if (partyCode == "A" || partyCode == "Andre" || partyCode == "Andre2" || partyCode == "BLANKE") {
      continue;
    }

    const votesData = party.stemmer ? party.stemmer.resultat : {};

    if (votesData.prosent > highestPercentage) {
      highestPercentage = votesData.prosent;
      paryName = party.id.navn;
    }
  }

  return paryName;
}

export function getHigestMandatPartyName(data) {
  let highestMandat = 0;
  let partiMandat = "";

  for (const party of data.partier) {
    let mandaterData = party.mandater ? party.mandater.resultat : {};

    if (data.prognose && data.prognose.beregnet == true) {
      mandaterData = party.mandater ? party.mandater.prognose : {}
    }

    try {
      if (mandaterData.antall > highestMandat) {
        highestMandat = mandaterData.antall;
        partiMandat = party.id.navn;
      }
    } catch (e) {
    }
  }

  return partiMandat;
}

export function getElectionLocation(data, searchItems) {
  return `${data.id.navn?.toUpperCase() || ''}${data.id.navnInfo || ''}`;
}

export function getElectionDistrictName(data) {
  return data?.id?.navn;
}

export function isElectionDistrict(data) {
  return data?.id?.nivaa == "fylke" && data.id.valgtype == 'ST'
}

export function isCountryOverview(data) {
  return data?.id?.nivaa == "land";
}

function getHistoricHoverText(data) {
  let historicHoverText = 'De lyse søylene viser resultatet i 2019';

  if (data.merknader && data.merknader.length > 0) {
    historicHoverText = 'De lyse søylene viser resultatet i 2019. Fylket eller kommunen er berørt av regionsreformen.';
  }

  return historicHoverText;
}

export function getPartyCode(party) {
  let partyCode = party.id.partikode;

  return formatPartyCode(partyCode);
}

export function getPartyName(party) {
  party?.id?.navn;
}

export function formatPartyCode(partyCode) {
  if (partyCode == "RØDT") {
    partyCode = "R";
  } else if (partyCode == "A") {
    partyCode = "AP";
  } else if (partyCode == "Andre") {
    partyCode = "A";
  }

  return partyCode;
}

export function getPartyColor(partyCode) {
  const parties = getParties();

  let color = "#ccc";
  for (const partyId of Object.keys(parties)) {
    const party = parties[partyId];
    if (party.shortName.toLowerCase() == partyCode.toLowerCase()) {
      color = party.color;
    }
  }
  return color;
}

export function sortMyParties(data) {
  data = [...data];
  let orderArr = ['R', 'SV', 'AP', 'SP', 'MDG', 'V', 'KRF', 'H', 'FRP',
    'RV', 'pp', 'PP', 'developer', 'lista', 'FNB', 'KRISTNE', 'BTN', 'KRLB', 'BLUT',
    'FLUT', 'DEMN', 'TLP', 'NORDK', 'ALLI', 'ARJA', 'HELSE', 'KYST', 'LIBS', 'NKP', 'PIR',
    'SAME', 'AKSAM', 'A'
  ];
  // let orderArr = [ 
  // 								'RV', 'SV', 'AP', 'developer', 'SP', 'MDG',
  // 								'V', 'KRF', 'H', 'FRP', 'A', 'lista', 'pp',
  // 								'PP', 'FNB', 'KRISTNE', 'BTN', 'KRLB', 'BLUT', 
  // 								'FLUT', 'DEMN' /*,''*/
  // 							];
  data.sort(function (a, b) {
    if (a.category > 0 && b.category > 0 && (orderArr.indexOf(a.parti) < 0 || orderArr.indexOf(b.parti) < 0)) {
      return (a.category - b.category)
    }
    return orderArr.indexOf(a.parti) - orderArr.indexOf(b.parti);
  });
  return data;
}

export function isOsloArea(areaId) {
  areaId = areaId + "";

  let check = false;
  if (areaId.split("-")[0] == '03') {
    check = true;
  }
  return check;
}

export function isKretArea(areaId) {
  areaId = areaId + "";

  return areaId.split("-").reverse()[0].length >= 3;
}

export function isNonMandateArea(areaId) {
  areaId = areaId + "";

  let check = false;
  if (areaId.split("-").length >= 2) {
    check = true;
  }

  return check;
}

export function isKretsOrMunicipality(areaId) {
  areaId = String(areaId);

  const parts = areaId.split("-")
  return parts.length > 2 || parts.length === 2 && parts.reverse()[0].length >= 3
}

export function isNationWideAreaId(areaId) {
  areaId = String(areaId);

  const parts = areaId.split("-")
  return parts[0] === "00"
}

export function getAreaIdTypeInfo(areaId) {
  areaId = areaId + "";

  if (!areaId) {
    areaId = '00';
  }

  let newActiveTab = 'FY';
  let newDisableTab = 'KO';

  if (isKretsOrMunicipality(areaId)) {
    newActiveTab = 'KO';
    newDisableTab = 'FY';
  }

  return ({
    areaId: areaId,
    activeTab: newActiveTab,
    disableTab: newDisableTab
  })
}

export function getInfoByAreaId(areaId, activeTab) {
  areaId = areaId + "";

  if (!areaId) {
    areaId = '00';
  }

  let newActiveTab = activeTab;
  let newDisableTab = '';

  if ((isOsloArea(areaId) || isKretArea(areaId))) {
    newActiveTab = 'KO';
    newDisableTab = 'FY';
  }

  return ({
    areaId: areaId,
    activeTab: newActiveTab,
    disableTab: newDisableTab
  })
}

export function getStTabInfoByAreaId(areaId, activeTab) {
  areaId = areaId + "";

  if (!areaId) {
    areaId = '00';
  }

  let newActiveTab = activeTab;
  let newDisableTab = '';

  if (isNonMandateArea(areaId)) {
    newActiveTab = 'RST';
    newDisableTab = 'NST';
  }

  return ({
    areaId: areaId,
    activeTab: newActiveTab,
    disableTab: newDisableTab
  })
}

function getParties() {
  return {
    1: new PartyClass(1, 'R', 'Rødt', '#800000'),
    2: new PartyClass(2, 'SV', 'Sosialistisk Venstreparti', '#D01921'),
    3: new PartyClass(3, 'AP', 'Arbeiderpartiet', '#D65E74'),
    4: new PartyClass(4, 'SP', 'Senterpartiet', '#00934C'),
    5: new PartyClass(5, 'V', 'Venstre', '#92B34D'),
    6: new PartyClass(6, 'KRF', 'Kristelig Folkeparti', '#FFCD00'),
    7: new PartyClass(7, 'H', 'Høyre', '#02B3FF'),
    8: new PartyClass(8, 'FRP', 'Fremskrittspartiet', '#0078B7'),
    9: new PartyClass(9, 'lista', 'lista', '#8c8c8c'),
    10: new PartyClass(10, 'MDG', 'Miljøpartiet De Grønne', '#739B1F'),
    1000: new PartyClass(1000, 'A', 'ANDRE', '#8c8c8c'),
    // 11: new PartyClass(3, 'A', 'Arbeiderpartiet', '#D65E74')
  };
}

function PartyClass(id, shortName, name, color) {
  this.id = id;
  this.shortName = shortName;
  this.name = name;
  this.color = color;
}

export function getPartyVoteData(data, party) {
  let votesData = party.stemmer ? party.stemmer.resultat : {}
  if (isPrioritizeForecast(data)) {
    votesData = party.stemmer ? party.stemmer.prognose : {}
  }
  return votesData;
}

export function getPartyMandatesData(data, party) {
  let mandaterData = party.mandater ? party.mandater.resultat : {};
  if (isPrioritizeForecast(data)) {
    mandaterData = party.mandater ? party.mandater.prognose : {}
  }
  return mandaterData;
}

export function isIgnorePartyResult(party) {
  // "BLANKE" => The "BLANKE" party is the "party" that gets blank votes and this party must of course be treatede specially by all clients that use this API.
  // "Andre" => The party "Andre(Other)" is a summation / aggregation of all parties that have a party category 2 or 3, but not the "BLANKE".
  // Andre2" => The party "Andre2" are the parties that do not run in this election, but which ran in at least one of the two previous elections. This party always gets 0 in percent and will always have a decline in the changes from previous elections
  const partyCode = getPartyCode(party);
  return partyCode == "Andre" || partyCode == "Andre2" || partyCode == "BLANKE"
}

export function isPrioritizeForecast(data) {
  return data.prognose && data.prognose.beregnet == true
}

export function findArrayIndex(array, callback) {
  for (var i = 0; i < array.length; i++) {
    if (callback(array[i])) {
      return i;
    }
  }
  return -1;
}



export function sortObjectByKeys(unordered) {
  const ordered = Object.keys(unordered).sort((a, b) => a.localeCompare(b, 'nb')).reduce(
    (obj, key) => {
      obj[key] = unordered[key];
      return obj;
    },
    {}
  );
  return ordered;
}

export function getDisticByNumber(number) {
  return getElectionDistricts()[number];
}

export function getDistrictByShortName(shortName) {
  const districts = getElectionDistricts();

  for (const districtNumber of Object.keys(districts)) {
    const district = districts[districtNumber];
    if (district.shortName == shortName) {
      return district;
    }
  }
}

export function getSortedDistrictShortNames(districtData) {
  const distictsKeys = Object.keys(districtData);

  return distictsKeys.sort((a, b) => {
    const first = getDistrictByShortName(a);
    const second = getDistrictByShortName(b);
    return first.ordering - second.ordering;
  })
}

export function getElectionDistricts() {
  return {
    "01": {
      name: "Østfold",
      shortName: "ØS",
      id: "01",
      totalMandates: 9,
      ordering: 19,
    },
    "02": {
      name: "Akershus",
      shortName: "AK",
      id: "02",
      totalMandates: 19,
      ordering: 1,
    },
    "03": {
      name: "Oslo",
      shortName: "OSL",
      id: "03",
      totalMandates: 20,
      ordering: 11,
    },
    "04": {
      name: "Hedmark",
      shortName: "HE",
      id: "04",
      totalMandates: 7,
      ordering: 5,
    },
    "05": {
      name: "Oppland",
      shortName: "OP",
      id: "05",
      totalMandates: 6,
      ordering: 10,
    },
    "06": {
      name: "Buskerud",
      shortName: "BU",
      id: "06",
      totalMandates: 8,
      ordering: 3,
    },
    "07": {
      name: "Vestfold",
      shortName: "VE",
      id: "07",
      totalMandates: 7,
      ordering: 18,
    },
    "08": {
      name: "Telemark",
      shortName: "TE",
      id: "08",
      totalMandates: 6,
      ordering: 15,
    },
    "09": {
      name: "Aust-Agder",
      shortName: "AA",
      id: "09",
      totalMandates: 4,
      ordering: 2,
    },
    "10": {
      name: "Vest-Agder",
      shortName: "VA",
      id: "10",
      totalMandates: 6,
      ordering: 17,
    },
    "11": {
      name: "Rogaland",
      shortName: "RO",
      id: "11",
      totalMandates: 14,
      ordering: 12,
    },
    "12": {
      name: "Hordaland",
      shortName: "HO",
      id: "12",
      totalMandates: 16,
      ordering: 6,
    },
    "14": {
      name: "Sogn og Fj",
      shortName: "SF",
      id: "14",
      totalMandates: 4,
      ordering: 13,
    },
    "15": {
      name: "Møre og Roms",
      shortName: "MR",
      id: "15",
      totalMandates: 8,
      ordering: 7,
    },
    "16": {
      name: "S-Trøndelag",
      shortName: "ST",
      id: "16",
      totalMandates: 10,
      ordering: 14,
    },
    "17": {
      name: "N-Trøndelag",
      shortName: "NT",
      id: "17",
      totalMandates: 5,
      ordering: 9,
    },
    "18": {
      name: "Nordland",
      shortName: "NO",
      id: "18",
      totalMandates: 9,
      ordering: 8,
    },
    "19": {
      name: "Troms",
      shortName: "TR",
      id: "19",
      totalMandates: 6,
      ordering: 16,
    },
    "20": {
      name: "Finnmark",
      shortName: "FI",
      id: "20",
      totalMandates: 5,
      ordering: 4,
    },
  }
}

export function getDistrictIdFromAreaId(areaId) {
  const districtIds = Object.keys(getElectionDistricts());
  
  let districtId = '';

  if (areaId)  {
    districtId = areaId.split('-')[0];
    if (!districtIds.includes(districtId)) {
      districtId = '';
    }
  }

  return districtId;
}

export function debugDistricData(districtsData) {
  const data = districtsData.find(data => data.id.nr == "16");
  return getDistrictRepresentatives(data);
}

export function getPresentativesAllDistricts(districtsData, candidateList) {
  let allRepresentatives = [];

  for (const data of districtsData) {
    const representatives = getDistrictRepresentatives(data, candidateList);
    allRepresentatives = [...allRepresentatives, ...representatives];
  }

  allRepresentatives = suffleMultiDistrictElectedRepresentative(allRepresentatives, candidateList);
  return allRepresentatives;
}

export function debugAllDistirctsData(districtsData) {
  const debugData = {}

  for (const data of districtsData) {
    const districtNumber = data.id.nr;
    const district = getDisticByNumber(districtNumber);
    const districtMandates = district?.totalMandates || 0;

    let representatives = [];
    let levellingSeats = 0;
    let totalMandates = 0;
    for (const party of data.partier) {
      const partikd = getPartyCode(party);
      if (isIgnorePartyResult(party)) {
        continue;
      }
      const votesData = getPartyVoteData(data, party);
      const mandaterData = getPartyMandatesData(data, party);

      // console.log("Votes data ", mandaterData)

      const partyRepresentatives = mandaterData.representanter;
      const levelingRepresenter = partyRepresentatives.filter(r => !!r.utjevningsmandat);
      totalMandates += partyRepresentatives.length;
      levellingSeats += levelingRepresenter.length;
    }
    debugData[districtNumber] = {
      totalMandates: totalMandates,
      levellingSeats: levellingSeats,
    }
  }

  return debugData;
}

function isNewRepresentative(districtNumber, party, person, candidateList) {
  const { name: districtName, ...parties } = parliamentMembers[districtNumber] || {};

  if (parties) {
    const partyName = party.identity.navn;
    const personName = person.navn;

    if (parties[partyName]) {
      return !parties[partyName].includes(personName);
    } else {
      return true;
    }

  }

  return false;

  // const districtParties = candidateList.valgdistrikter[districtNumber].partier;
}

function getMatchCandidate(districtNumber, party, person, candidateList) {
  const partyName = party.identity.navn;
  const personName = person.navn;
  const candidateDistrict = (candidateList.valgdistrikter || []).find(d => d.valgdistrikt == districtNumber);
  const districtParties = candidateDistrict?.partier || [];
  const candidateparty = districtParties.find(p => p.partinavn == partyName);

  if (candidateparty) {
    const candidates = candidateparty.kandidater;
    const matchedParty = candidates.find(c => c.navn == personName);
    return matchedParty;
  }
}

export function countNewRepresentatives(representatives = []) {
  return representatives.filter(r => r.isNew).length;
}

export function getDistrictRepresentatives(data, candidateList) {
  // console.log("Candiadte list ", candidateList)
  const parties = [
    {
      partyCode: 'R',
      title: 'Rødt',
      seats: 0,
      seatOrder: [],
    },
    {
      partyCode: 'SV',
      title: 'SV',
      seats: 0,
      seatOrder: [],
    },
    {
      partyCode: 'AP',
      title: 'AP',
      seats: 0,
      seatOrder: [],
    },
    {
      partyCode: 'SP',
      title: 'SP',
      seats: 0,
      seatOrder: [],
    },
    {
      partyCode: 'MDG',
      title: 'MDG',
      seats: 0,
      seatOrder: [],
    },
    {
      partyCode: 'V',
      title: 'V',
      seats: 0,
      seatOrder: [],
    },
    {
      partyCode: 'KRF',
      title: 'KRF',
      seats: 0,
      seatOrder: [],
    },
    {
      partyCode: 'H',
      title: 'Høyre',
      seats: 0,
      seatOrder: [],
    },
    {
      partyCode: 'FRP',
      title: 'FRP',
      seats: 0,
      seatOrder: [],
    },
    // {
    //   partyCode: 'A',
    //   title: 'Andre',
    //   seats: 0,
    //   seatOrder: [],
    // },
    // {
    //   partyCode: 'PF',
    //   title: 'Pasientfokus',
    //   seats: 0,
    //   seatOrder: [],
    // }
  ]

  const districtNumber = data.id.nr;
  const district = getDisticByNumber(districtNumber);
  const districtMandates = district?.totalMandates || 0;
  const isForecast = isPrioritizeForecast(data);

  for (const party of data.partier) {
    const partikd = getPartyCode(party);
    const partyNavn = getPartyName(party);

    if (partikd == "Andre2" || partikd == "BLANKE" || partikd == "A") {
      continue;
    }

    const votesData = getPartyVoteData(data, party);
    const mandaterData = getPartyMandatesData(data, party);

    // console.log("Votes data ", votesData)

    const partyIndex = parties.findIndex(p => p.partyCode === partikd);

    if (partyIndex !== -1) {
      parties[partyIndex].votesData = votesData
      parties[partyIndex].mandaterData = mandaterData
      parties[partyIndex].identity = party.id;
    } else {

      let partyIndex = parties.findIndex(p => p.partyCode === partikd);

      if (partyIndex === -1) {
        parties.push({
          partyCode: partikd,
          title: partyNavn,
          seats: 0,
          seatOrder: [],
        });
        partyIndex = parties.length - 1;
      }
      
      parties[partyIndex].votesData = votesData
      parties[partyIndex].mandaterData = mandaterData
      parties[partyIndex].identity = party.id;
    }
  }

  // console.log("The parties ", parties)

  // console.log("The selected district ", district)

  const runnerupQutients = [];
  for (let i = 1; i <= districtMandates; i++) { // One seat will be out of equation. Determined globally
    const quotients = [];

    for (const party of parties) {
      // const totalVotes = party.votesData?.antall?.total || 0;

      const totalMandates = party.mandaterData?.antall || 0;
      const levelingMandate = party.mandaterData?.utjevningAntall || 0;

      const lastMandateQuotient = party.mandaterData?.sisteMandat?.kvotient || 0;
      const competitionMandates = totalMandates - levelingMandate;
      const s = competitionMandates > 0 ? (competitionMandates - 1) : 0;
      const totalVotes = Math.round(lastMandateQuotient * (s == 0 ? 1.4 : (2 * s + 1)));

      // console.log("The total votes Party ", party.partyCode, ' ', totalVotes)

      let divisor = 1.4;
      if (party.seats > 0) {
        divisor = 2 * party.seats + 1;
      }
      const quotient = totalVotes / divisor;

      // console.log("THe quotient ", quotient)

      if (quotient > 0) {
        if (i === districtMandates) {
          runnerupQutients.push({
            partyCode: party.partyCode,
            quotient: quotient,
          })
        } else {
          quotients.push({
            partyCode: party.partyCode,
            quotient: quotient,
          });
        }
      }
    }

    if (i === districtMandates) {
      break;
    }

    const winningParty = maxBy(quotients, 'quotient');

    // console.log("The wining party ",  districtNumber, ' ', winningParty)

    const partyIndex = parties.findIndex(p => p.partyCode === winningParty?.partyCode);
    if (partyIndex !== -1) {
      parties[partyIndex].seats += 1;
      parties[partyIndex].lastQuotient = winningParty.quotient;
      parties[partyIndex].seatOrder.push(i);

      // console.log("Party seat order ", parties[partyIndex].seatOrder)
    }
  }

  const levelingRepresenter = {};
  let representatives = [];
  for (const party of parties) {

    // console.log("The party ", party)
    const representers = party.mandaterData.representanter;
    if (representers?.length > 0) {
      if (!levelingRepresenter.person) {
        const person = representers.find(r => !!r.utjevningsmandat);
        if (person) {
          const matchedCandidate = getMatchCandidate(districtNumber, party, person, candidateList);
          if (matchedCandidate) {
            levelingRepresenter.altingetId = matchedCandidate.id;
            levelingRepresenter.isNew = !matchedCandidate.electedLastElection;
            levelingRepresenter.plassnummer = matchedCandidate.plassnummer;
            person.alder = calculateAge(matchedCandidate.fødselsdato);
          }

          levelingRepresenter.person = person;
          levelingRepresenter.party = { ...party, isForecast: isForecast };
          levelingRepresenter.district = { ...district, isForecast: isForecast };
          levelingRepresenter.seatOrder = districtMandates;
          levelingRepresenter.levelingSeat = true;
          levelingRepresenter.isForecast = isForecast;

          // levelingRepresenter.isNew = isNewRepresentative(districtNumber, party, person, candidateList);
        }

      }

      const competitionRepresenters = representers.filter(r => !r.utjevningsmandat);

      for (let s = 0; s < competitionRepresenters.length; s++) {
        const seatOrder = party.seatOrder[s];
        const person = competitionRepresenters[s];
        const representative = {
          person: person,
          seatOrder: seatOrder,
          district: { ...district, isForecast: isForecast },
          party: { ...party, isForecast: isForecast },
          isForecast: isForecast,
          // isNew: isNewRepresentative(districtNumber, party, person),
        }

        const matchedCandidate = getMatchCandidate(districtNumber, party, person, candidateList);
        if (matchedCandidate) {
          representative.altingetId = matchedCandidate.id;
          representative.isNew = !matchedCandidate.electedLastElection;
          representative.plassnummer = matchedCandidate.plassnummer;
          representative.person.alder = calculateAge(matchedCandidate.fødselsdato);
        }

        representatives.push(representative);
      }
    }
  }

  representatives = orderBy(representatives, 'seatOrder')

  if (runnerupQutients.length > 0) {
    const runnerupParty = maxBy(runnerupQutients, 'quotient');
    const partyIndex = parties.findIndex(p => p.partyCode === runnerupParty.partyCode);
    if (partyIndex !== -1) {
      const runnerupParty = parties[partyIndex];
      const person = runnerupParty.mandaterData.nesteKandidater[0] || {};
      const runnerupRepresentative = {
        person: person,
        district: { ...district, isForecast: isForecast },
        party: { ...runnerupParty, isForecast: isForecast },
        isForecast: isForecast,
      }

      const matchedCandidate = getMatchCandidate(districtNumber, runnerupParty, person, candidateList);
      if (matchedCandidate) {
        runnerupRepresentative.altingetId = matchedCandidate.id;
        runnerupRepresentative.isNew = !matchedCandidate.electedLastElection;
        runnerupRepresentative.plassnummer = matchedCandidate.plassnummer;
        runnerupRepresentative.person.alder = calculateAge(matchedCandidate.fødselsdato);
      }

      if (representatives.length > 0) {
        // console.log("runner up representat ive ",runnerupRepresentative)
        // console.log("Total competition reqpres ", representatives.length)
        // console.log("Competition repres... ", JSON.parse(JSON.stringify(representatives)))
        const lastCompetitiveIndex = representatives.length - 1;
        // console.log("Competitve seat order ", representatives[lastCompetitiveIndex].altingetId)
        representatives[lastCompetitiveIndex] = {
          ...representatives[lastCompetitiveIndex],
          runnerup: runnerupRepresentative,
        }
      }
    }
  }

  if (levelingRepresenter.party) {
    representatives.push({
      ...levelingRepresenter,
      party: {
        ...levelingRepresenter.party,
        seatOrder: [...levelingRepresenter.party.seatOrder, districtMandates]
      }
    })
  }

  // console.log("THe district number ", districtNumber)
  // console.log("THe representativess ", representatives)
  // console.log("Candidate list inside ", candidateList)

  // console.log("Representative inside ", JSON.stringify(representatives))

  return representatives;
}

export function suffleMultiDistrictElectedRepresentative(representatives, candidateList) {
  // representatives = [...representatives]

  // console.log("The representatives ", representatives)
  const altingetIds = representatives.map(r => r.altingetId);
  const filteredIds = altingetIds.filter((id, i) => altingetIds.indexOf(id) !== i)
  const uniqueIds = new Set(filteredIds);
  const multiElectedIds = Array.from(uniqueIds);

  // console.log("Multie elected ids ", multiElectedIds)
  // console.log("candidate list ", candidateList)

  const multiElectedRepresentatives = {};

  multiElectedIds.forEach((mrId) => {
    if (!multiElectedRepresentatives[mrId]) {
      multiElectedRepresentatives[mrId] = [];
    }

    representatives.forEach((representative, i) => {
      if (mrId === representative.altingetId) {
        multiElectedRepresentatives[mrId].push({ representative: representative, index: i });
      }
    });

    multiElectedRepresentatives[mrId].sort((a, b) => a.representative.district.ordering - b.representative.district.ordering);

    const restRps = multiElectedRepresentatives[mrId].slice(1);


    for (const restRp of restRps) {
      const { remainingCandidates } = getRepresentativeCandidatesList(restRp.representative, candidateList);
      let newRestRp = restRp;
      for (const rc of remainingCandidates) {
        const rcId = rc.id;

        const isAlreadyElected = representatives.filter(r => r.altingetId === rcId).length > 0;
        if (isAlreadyElected) {
          const rpIndex = newRestRp.index;
          const rp = newRestRp.representative;
  
          const newRp = {
            ...newRestRp.representative,
            altingetId: rc.id,
            person: {
              ...rc,
              alder: calculateAge(rc.fødselsdato)
            },
            plassnummer: rc.plassnummer,
          }
  
          const matchedCandidate = getMatchCandidate(rp.district.id, rp.party, rc, candidateList);
          if (matchedCandidate) {
            newRp.altingetId = matchedCandidate.id;
            newRp.isNew = !matchedCandidate.electedLastElection;
            newRp.plassnummer = matchedCandidate.plassnummer;
            newRp.person.alder = calculateAge(matchedCandidate.fødselsdato);
          }
  
          representatives[rpIndex] = newRp;

          representatives.forEach((representative, i) => {
            if (rcId === representative.altingetId && representative.district.id === newRestRp.representative.district.id) {
              newRestRp = { representative: representative, index: i }
            }
          });
          
          continue;
        }

        const runnerupMandates = [];
        representatives.forEach((representative, i) => {
          if (representative.runnerup && representative.runnerup.altingetId === rcId ) {
            runnerupMandates.push({representative: representative, index: i})
          }
        })

        if (runnerupMandates.length > 0) {
          for (const rum of runnerupMandates) {
            const ruc = rum.representative
            const { runnerupRemainingCandidates } = getRepresentativeCandidatesList(ruc.representative, candidateList);
            const nRunnerup = runnerupRemainingCandidates[0];
            const newRunnerup = {
              ...ruc,
              altingetId: nRunnerup.id,
              person: {
                ...ruc.person,
                ...nRunnerup,
              }
            };
            representatives[ruc.index].runnerup = newRunnerup; 
          }
        }

        const rpIndex = newRestRp.index;
        const rp = newRestRp.representative;

        const newRp = {
          ...newRestRp.representative,
          altingetId: rc.id,
          person: {
            ...rc,
            alder: calculateAge(rc.fødselsdato)
          },
          plassnummer: rc.plassnummer,
        }

        const matchedCandidate = getMatchCandidate(rp.district.id, rp.party, rc, candidateList);
        if (matchedCandidate) {
          newRp.altingetId = matchedCandidate.id;
          newRp.isNew = !matchedCandidate.electedLastElection;
          newRp.plassnummer = matchedCandidate.plassnummer;
          newRp.person.alder = calculateAge(matchedCandidate.fødselsdato);
        }

        representatives[rpIndex] = newRp;
        break;
      }
    }
  });


  // console.log("New suffled representative ", representatives)

  // representatives.forEach((representative, i) => {
  //   if (multiElectedIds.includes(representative.altingetId)) {
  //     multiRepresentatives.push({ representative: representative, index: i });
  //   }
  // })

  // console.log("Multie district representatives ", multiElectedRepresentatives)

  representatives = representatives.map(r => {
    if (!MAIN_PARTY_CODES.includes(r.party.partyCode)) {
      return {
        ...r,
        party: {
          ...r.party,
          partyCode: 'And',
          title: 'Andre',
        }
      }
    } else {
      return r;
    }
  })

  return representatives;
}

export function getRepresentativeCandidatesList(representative, candidateList) {
  const plassnummer = representative.plassnummer;
  const district = representative.district;
  const districts = candidateList.valgdistrikter || [];
  const matchedDistrict = districts.find(d => d.valgdistrikt === district.id);
  const party = representative.party;
  const districtParty = matchedDistrict?.partier?.find(p => p.partikode === party.identity.partikode);
  const partyCandidates = districtParty?.kandidater || [];
  const remainingCandidates = partyCandidates.slice(plassnummer);
  return {candidates: partyCandidates, remainingCandidates: remainingCandidates};
}

export function groupArrayObjectByPath(arrayObject, path) {
  return arrayObject.reduce(function (a, c) {
    const pathValue = get(c, path, [])
    a[pathValue] = a[pathValue] || [];
    a[pathValue].push(c);
    return a;
  }, {});
}

export function groupRepresentativesByDistrict(representatives) {
  const groupedDistrict = groupArrayObjectByPath(representatives, 'district.shortName');
  return sortObjectByKeys(groupedDistrict);
}

export function groupRepresentativesByParty(representatives) {
  const groupedParties = groupArrayObjectByPath(representatives, 'party.partyCode');
  const sortedGroups = {};
  for (const partyCode of PARTY_CODES_IN_ORDER) {
    if (groupedParties[partyCode]) {
      sortedGroups[partyCode] = groupedParties[partyCode].sort((a, b) => a.district.shortName.localeCompare(b.district.shortName) || a.seatOrder - b.seatOrder);
    }
  }
  return sortedGroups;
}