import axios from 'axios';
import { getCandidateDetailsUrl } from '../helpers/utils';

const candidateBaseUrl = 'https://kandidatbasen.ntb-it.com/widget/api';

class CandidateService {
  async searchCandidates(searchTerm) {
    return axios.get(candidateBaseUrl + "/candidates", { params: { search: searchTerm } })
      .then(res => {
        const data = res.data;
        return data;
      })
      .catch(error => {
        return [];
      });
  }

  async getCandidateById(id) {
    return axios.get(candidateBaseUrl + "/candidate", { params: { id: id } })
      .then(res => {
        const data = res.data;
        return data;
      })
      .catch(error => {
        return undefined;
      });
  }

  async getCandidateByFileUrl(fileUrl) {
    return axios.get(fileUrl)
      .then(res => {
        const data = res.data;
        data.politicsInterest = data.PoliticsInterest;
        delete data.PoliticsInterest;
        return data;
      })
      .catch(error => {
        return undefined;
      });
  }
}

export default CandidateService;