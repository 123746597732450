import React, {useEffect, useLayoutEffect, useRef, useState} from 'react'
import * as d3 from 'd3';
import classNames from './../../helpers/classnames'
import groupBy from "lodash-es/groupBy";
import {getPartyColor} from "../../helpers/charts-helper";
import useWindowSize from "../../hooks/use-winow-size";

const Line = React.memo(({chartData, selectedParties}) => {
  if (chartData.length === 0) {
    return null
  }

  chartData = chartData.filter(i => i.value !== 0)

  const [data, setData] = useState(chartData)

  useEffect(() => {
    if (!selectedParties || !selectedParties.length) return null;

    setData(chartData.filter(i => selectedParties.includes(i.label)))
  }, [selectedParties])

  const initialRender = useRef(true);
  const windowWidthTimer = useRef(null);
  const [windowWidth, setWindowWidth] = useState(0);
  const windowSize = useWindowSize();

  const containerRef = useRef(null);
  const chartRef = useRef(null)

  const margin = {top: 30, right: 30, bottom: 30, left: 43};

  const outerWidth = (windowWidth > 320 ? (windowWidth >= 640 ? 700 : windowWidth - margin.left) : 316);
  let outerHeight = 275

  // Set the dimensions and margins of the graph
  const width = outerWidth - margin.left - margin.right;
  const height = outerHeight - margin.top - margin.bottom;

  useEffect(() => {
    clearTimeout(windowWidthTimer.current);

    if (initialRender.current) {
      setWindowWidth(windowSize.width);
      initialRender.current = false;
    } else {
      windowWidthTimer.current = setTimeout(() => {
        setWindowWidth(windowSize.width);
      }, 300);
    }
  }, [windowSize?.width, initialRender]);

  useLayoutEffect(() => {
    d3.select(chartRef.current).html('')

    // tooltips
    const Tooltip = d3.select(chartRef.current)
      .append('div')
      .style('opacity', 0)
      .attr('class', classNames('tooltip'))
      .style('background-color', '#F7F7F7')
      .style('border-radius', '8px')
      .style('padding', '10px 15px')

    const tooltipMouseover = () => {
      Tooltip.style("opacity", 1);
    }

    const tooltipMousemove = (event, year) => {
      const parties = data.filter(x => x.year === year).sort((a, b) => b.value - a.value)

      Tooltip
        .html(() => {
          let html = ""
          html += `<div style="font-weight: bold; font-size: 12px; letter-spacing: 3px; margin-bottom: 5px;">${year}</div>`
          html += `<table cellspacing="0" cellpadding="0">`
            parties.forEach(party => {
              const value = (new Intl.NumberFormat("nb-NO")).format(party.value.toFixed(2))

              html += `<tr style="font-size: 10px;">
                <td style="text-align: right; border-right: 1px solid #707070; padding-right: 5px;">${value}%</td>
                <td style="padding-left: 5px;">${party.label}</td>
              </tr>`
            })
          html += `</table>`

          return html
        })
        .style("left", (d3.pointer(event)[0] + chartRef.current.offsetLeft - 60) + "px")
        .style("top", (d3.pointer(event)[1] + chartRef.current.offsetTop - 20) + "px")
    }

    const mouseleaveTooltip = (d) => {
      Tooltip.style("opacity", 0)
    }

    // append the svg object to the body of the page
    const svg = d3.select(chartRef.current)
      .append("svg")
      .attr("width", outerWidth)
      .attr("height", outerHeight)
      .append("g")
      .attr("transform",
        "translate(" + margin.left + "," + margin.top + ")");

    const groups = groupBy(data, 'label')
    const stat = Object.keys(groups).map(key => ({
      key,
      color: getPartyColor(key),
      values: groups[key]
    }))

    // Add X axis --> it is a date format
    const x = d3.scaleLinear()
      .domain(d3.extent(data, (d) => d.year))
      .range([0, width]);

    // Add Y axis
    const y = d3.scaleLinear()
      .domain([0, d3.max(data, (d) => +d.value)])
      .range([height, 0]);

    // Color palette
    const res = stat.map(d => d.key)

    const color = d3.scaleOrdinal()
      .domain(res)
      .range(stat.map(d => d.color))

    const years = [...new Set(data.map(d => d.year))]

    function lineMouseover (e, d) {
      const partyKey = d.key || d.label

      d3.select(`.${classNames('line__lines')}[party-key=${partyKey}]`)
        .attr("stroke-width", 4)
        .raise()

      d3.selectAll(`.${classNames('line__circles')}[party-key=${partyKey}]`)
        .attr("r", 5)
        .raise()

      d3.select(`.${classNames('line__labels')}[party-key=${partyKey}]`)
        .attr("font-weight", "bold")
        .raise()

      d3.selectAll(`.${classNames('line__labels')}:not([party-key=${partyKey}])`)
        .attr("opacity", 0)
    }

    function lineMouseleave (e, d) {
      const partyKey = d.key || d.label

      d3.select(`.${classNames('line__lines')}[party-key=${partyKey}]`)
        .attr("stroke-width", 2)

      d3.selectAll(`.${classNames('line__circles')}[party-key=${partyKey}]`)
        .attr("r", 4)

      d3.select(`.${classNames('line__labels')}[party-key=${partyKey}]`)
        .attr("font-weight", null)

      d3.selectAll(`.${classNames('line__labels')}:not([party-key=${partyKey}])`)
        .attr("opacity", 1)
    }

    // Draw X axis labels
    svg.append("g")
      .selectAll(`.${classNames('line__years')}`)
      .data(years)
      .enter()
      .append('text')
      .attr('class', classNames('line__years'))
      .attr('text-anchor', 'middle')
      .attr('font-weight', 'bold')
      .attr('font-size', '12px')
      .attr('fill', 'currentColor')
      .attr('style', 'letter-spacing: 3px;')
      .attr("x", (i) => x(i))
      .attr("y", height + 25)
      .text((i) => i)
      .attr("opacity", 0)
      .transition()
      .duration(800)
      .attr("opacity", 1)

    // Draw a dotted line per X axis stretching
    svg.append("g")
      .selectAll(`.${classNames('line__dottedLines')}`)
      .data(years)
      .enter()
      .append('line')
      .attr('class', classNames('line__dottedLines'))
      .attr('stroke-width', 1)
      .attr('stroke', '#E0E0E0')
      .attr('stroke-dasharray', '1,2')
      .attr('x1', (i) => x(i))
      .attr('x2', (i) => x(i))
      .attr('y1', height + 10)
      .attr('y2', -30)

    // Draw a line per X axis that is bigger and invisible for the tooltip
    svg.append("g")
      .selectAll(`.${classNames('line__xLines')}`)
      .data(years)
      .enter()
      .append('line')
      .attr('class', classNames('line__xLines'))
      .attr('stroke-width', 20)
      .attr('stroke', 'transparent')
      .attr('x1', (i) => x(i))
      .attr('x2', (i) => x(i))
      .attr('y1', height + 10)
      .attr('y2', -30)
      .on("mouseover", tooltipMouseover)
      .on("mousemove", tooltipMousemove)
      .on("mouseleave", mouseleaveTooltip)

    // Draw the lines
    svg.append("g")
      .selectAll(`.${classNames('line__lines')}`)
      .data(stat)
      .enter()
      .append("path")
      .attr('class', classNames('line__lines'))
      .attr("fill", "none")
      .attr("stroke", (d) => color(d.key))
      .attr("stroke-width", 2)
      .attr("opacity", 0)
      .attr("party-key", (d) => d.key)
      .on("mouseover", lineMouseover)
      .on("mouseleave", lineMouseleave)
      .transition()
      .duration(800)
      .attr("opacity", 1)
      .attr("d", (d) => d3.line()
        .x((d) => x(d.year))
        .y((d) => y(+d.value))
        (d.values))


    // Draw a group of labels for each party
    svg.append("g")
      .selectAll(`.${classNames('line__labels')}`)
      .data(stat)
      .enter()
      .append("text")
      .attr('class', classNames('line__labels'))
      .attr("transform", (d) => `translate(0, ${y(d.values[0].value)})`)
      .attr('party-key', (d) => d.key)
      .attr("x", "-8px")
      .attr("dy", "0.35em")
      .attr('text-anchor', 'end')
      .attr('font-size', '10px')
      .attr('fill', 'currentColor')
      .text((d) => d.key)
      .on("mouseover", lineMouseover)
      .on("mouseleave", lineMouseleave)
      .attr("opacity", 0)
      .transition()
      .duration(800)
      .attr("opacity", 1)

    // Draw a group of circles
    svg.append("g")
      .selectAll(`.${classNames('line__circles')}`)
      .data(data)
      .join("circle")
      .attr('class', classNames('line__circles'))
      .attr("stroke-width", "1px")
      .attr("stroke", "#FFFFFF")
      .attr("party-key", (d) => d.label)
      .attr("r", 4)
      .attr("cx", (d) => x(d.year))
      .attr("cy", (d) => y(d.value))
      .attr("fill", (d) => getPartyColor(d.label))
      .on("mouseover", function (e, d) {
        tooltipMouseover(e, d.year)
        lineMouseover(e, d)
      })
      .on("mousemove", (e, d)=> tooltipMousemove(e, d.year))
      .on("mouseleave", function (e, d) {
        mouseleaveTooltip(e, d.year)
        lineMouseleave(e, d)
      })
      .attr("opacity", 0)
      .transition()
      .duration(800)
      .attr("opacity", 1)
  }, [data, windowWidth, windowSize?.width])

  return <div className={classNames('line')} ref={containerRef}>
    <div ref={chartRef}/>
  </div>
})

export default Line