import {
  filterPartiesData,
  findArrayIndex,
  getIndexOfPartyWithCodeA,
  getMaxVoteAndrePercentage,
  getPartiesDataMandates,
  getPartisDataVotePercentage,
  getPartyCode,
  getPartyColor,
  getRoundedPercentage,
  otherPariesPercentageData,
  sortMyParties,
} from './charts-helper';

/**
 * Party Categories:
 * =================
 * 1 = Stortingspartier (Storting parties)
 * 2 = Landsdekkende partier (Nationwide parties)
 * 3 = Lokale partier + BLANKE (Local parties + BLANKE)
 */

export class ChartsData {
  constructor(data) {
    this.data = data;
    this.mandatesChartData = [];
    this.lastElectionBarChartData = [];
    this.currentElectionBarChartData = [];
    this.electionHistoryLineChartData = [];

    this.lastElectionBarChartDataBackup = [];
    this.currentElectionBarChartDataBackup = [];
  }

  getChartsData() {
    this.processChartsData();

    return {
      barChartHistoryData: sortMyParties(this.lastElectionBarChartData),
      barChartCurrentData: sortMyParties(this.currentElectionBarChartData),
      mandatesChartData: sortMyParties(this.mandatesChartData),
    };
  }
  
  processChartsData() {
    this.populateInitialChartsData();
    this.backupInitialChartsData();
    this.filterBarChartsData();
    this.populateBarChartsPartyAData();
    // this.populateMandateChartPartyAData();
    this.roundChartData();
    this.prepareMandateChartData();
  }

  /**
   * Populate initial charts data from json data
   */
  populateInitialChartsData() {
    for (const party of this.data.partier) {
      const partikd = getPartyCode(party);

      if (this.isIgnorePartyResult(party)) {
        continue;
      }

      const votesData = this.getPartyVoteData(party);
      const mandaterData = this.getPartyMandatesData(party);
      const myMandater = mandaterData.antall || 0;

      // console.log("THe party ", party)
      // console.log("The mandater ", myMandater)

      if (!votesData.prosent) {
        votesData.prosent = 0;
      }

      if (myMandater > 0 || votesData.prosent > 0 || party.id.partikategori > 0) {

        const partyColor = getPartyColor(partikd);
        const changePercentage = votesData.endring ? votesData.endring.samme : 0;
        const lastElectionVotePercentage = changePercentage == null ? 0 : votesData.prosent + ((-1) * changePercentage)
        const currentElectionVotePercentage = votesData.prosent;
        const mandatesChange = mandaterData?.endring || 0;

        this.lastElectionBarChartData.push({ category: party.id.partikategori, label: partikd, parti: partikd, name: party.id.navn, stemmer: lastElectionVotePercentage, color: partyColor })
        this.currentElectionBarChartData.push({ category: party.id.partikategori, label: partikd, parti: partikd, name: party.id.navn, stemmer: currentElectionVotePercentage, percentageChange: changePercentage, mandatesChange: mandatesChange, color: partyColor, mandater: myMandater, value: myMandater })
        // this.mandatesChartData.push({ category: party.id.partikategori, parti: partikd, label: partikd, value: myMandater, mandatesChange: mandatesChange, color: partyColor });
      }
    }

    // console.log("Current bar chart data ", JSON.stringify(this.currentElectionBarChartData))
  }

  /***
   * Duplicate the initial charts data to get other party(Part A) data later.
   */
  backupInitialChartsData() {
    this.lastElectionBarChartDataBackup = this.lastElectionBarChartData.slice(0);
    this.currentElectionBarChartDataBackup = this.currentElectionBarChartData.slice(0);
    this.mandatesChartDataBackup = this.mandatesChartData.slice(0);
  }

  filterBarChartsData() {
    this.currentElectionBarChartData = filterPartiesData(this.currentElectionBarChartData);
    this.lastElectionBarChartData = filterPartiesData(this.lastElectionBarChartData);
  }

  /***
   * Populate max other party chart data and PartyA(Andre) chart data
   */
  populateBarChartsPartyAData() {
    const currentElectionOtherPartiesData = otherPariesPercentageData(this.currentElectionBarChartDataBackup, this.currentElectionBarChartData)
    const lastElelectionOtherPartiesData = otherPariesPercentageData(this.lastElectionBarChartDataBackup, this.lastElectionBarChartData)

    let currentElectionOtherPercentage = getPartisDataVotePercentage(currentElectionOtherPartiesData);
    let lastElectionOtherPercentage = getPartisDataVotePercentage(lastElelectionOtherPartiesData);

    let currentElectionOtherMandates = getPartiesDataMandates(currentElectionOtherPartiesData);

    // console.log("Current election other mandates ", currentElectionOtherMandates)
    
    let lastElectionOtherMandates = getPartiesDataMandates(lastElelectionOtherPartiesData);

    const { party: currentMaxVotedOtherParty } = getMaxVoteAndrePercentage(currentElectionOtherPartiesData);

    // console.log("Max othe party ", currentMaxVotedOtherParty)
    if (currentMaxVotedOtherParty) {

      this.currentElectionBarChartData.push(currentMaxVotedOtherParty);

      // Reduce the percentage of max voted other pary from the all other parties percentage.
      currentElectionOtherPercentage = currentElectionOtherPercentage - currentMaxVotedOtherParty.stemmer;
      currentElectionOtherMandates = currentElectionOtherMandates - (currentMaxVotedOtherParty.mandater || 0);

      // Find the current max voted other party in last election data and reduce that party last election percentage
      // from last election other parties percentage.
      var lastElectionOtherPartyDataIndex = findArrayIndex(lastElelectionOtherPartiesData, function (d) {
        return currentMaxVotedOtherParty.parti === d.parti;
      });
      if (lastElectionOtherPartyDataIndex > -1) {
        const lastElectionOtherParty = { ...lastElelectionOtherPartiesData[lastElectionOtherPartyDataIndex] };
        this.lastElectionBarChartData.push(lastElectionOtherParty)
        lastElectionOtherPercentage = lastElectionOtherPercentage - lastElectionOtherParty.stemmer
        lastElectionOtherMandates = lastElectionOtherMandates - (lastElectionOtherParty.mandater || 0);
      }
    }

    const currentElectionPartAPercentage = currentElectionOtherPercentage;
    const lastElectionPartAPercentage = lastElectionOtherPercentage;

    const partyAPercentageChange = currentElectionOtherPercentage - lastElectionOtherPercentage;

    const currentElectionPartAMandates = currentElectionOtherMandates;
    const lastElectionPartyAMandates = lastElectionOtherMandates;
    const partyAMandatesChange = currentElectionPartAMandates - lastElectionPartyAMandates;

    const colorP = getPartyColor("A");

    const currentElectionPartyAObj = { category: 0, label: "A", parti: "A", stemmer: currentElectionPartAPercentage, percentageChange: partyAPercentageChange,  mandatesChange: partyAMandatesChange, mandater: currentElectionPartAMandates, value: currentElectionPartAMandates, color: colorP };
    const lastElectionPartyAObj = { category: 0, label: "A", parti: "A", stemmer: lastElectionPartAPercentage, color: colorP };

    const currentElectionPartyAIndex = getIndexOfPartyWithCodeA(this.currentElectionBarChartData);
    if (currentElectionOtherPercentage > 0) {
      if (currentElectionPartyAIndex < 0) {
        this.currentElectionBarChartData.push(currentElectionPartyAObj);
      } else {
        this.currentElectionBarChartData[currentElectionPartyAIndex].stemmer = currentElectionPartyAObj.stemmer;
        this.currentElectionBarChartData[currentElectionPartyAIndex].percentageChange = currentElectionPartyAObj.percentageChange;
        this.currentElectionBarChartData[currentElectionPartyAIndex].mandatesChange = currentElectionPartyAObj.mandatesChange;
        this.currentElectionBarChartData[currentElectionPartyAIndex].mandater = currentElectionPartyAObj.mandater;
        this.currentElectionBarChartData[currentElectionPartyAIndex].value = currentElectionPartyAObj.mandater;
      }
    }

    const lastElectionPartyAIndex = getIndexOfPartyWithCodeA(this.lastElectionBarChartData);
    if (lastElectionOtherPercentage > 0) {
      if (lastElectionPartyAIndex < 0) {
        this.lastElectionBarChartData.push(lastElectionPartyAObj);
      } else {
        this.lastElectionBarChartData[lastElectionPartyAIndex].stemmer = lastElectionPartyAObj.stemmer;
      }
    }
  }

  // /***
  //  * Populate max other party chart data and PartyA(Andre) mandates data
  //  */
  // populateMandateChartPartyAData() {
  //   const colorP = getPartyColor("A");
  //   const partiesCodes = this.currentElectionBarChartData.map(function (d) { return d.parti; });

  //   let otherPartyMandates = 0;
  //   const otherPartyIndex = getIndexOfPartyWithCodeA(this.mandatesChartData);
  
  //   if (otherPartyIndex > -1) {
  //     otherPartyMandates = this.mandatesChartData[otherPartyIndex].value;
  //   }
  
  //   for (const partyData of this.mandatesChartDataBackup) {
  //     if (partiesCodes.indexOf(partyData.parti) == -1) {
  //       otherPartyMandates += partyData.value;
  //     }
  //   }
  
  //   const otherPartyMandatesObj = { label: "A", value: otherPartyMandates, parti: "A", color: colorP };
  //   if (otherPartyMandates > 0) {
  //     if (otherPartyIndex < 0) {
  //       this.mandatesChartData.push(otherPartyMandatesObj)
  //     } else {
  //       this.mandatesChartData[otherPartyIndex].value = otherPartyMandatesObj.value;
  //     }
  //   }
  //   this.mandatesChartData = this.mandatesChartData.filter(function (d) { return d.value > 0 && (partiesCodes.indexOf(d.parti) > -1 || d.parti == "A") })
  // }


  roundChartData() {
    this.currentElectionBarChartData = this.currentElectionBarChartData.map(data => ({
      ...data,
      stemmer: getRoundedPercentage(data.stemmer),
      percentageChange: getRoundedPercentage(data.percentageChange)
    }));

    this.lastElectionBarChartData = this.lastElectionBarChartData.map(data => ({
      ...data,
      stemmer: getRoundedPercentage(data.stemmer),
    }));
    
  }

  prepareMandateChartData() {
    this.mandatesChartData = this.currentElectionBarChartData.map(p => p);
  }


  getPartyVoteData(party) {
    let votesData = party.stemmer ? party.stemmer.resultat : {}
    if (this.isPrioritizeForecast()) {
      votesData = party.stemmer ? party.stemmer.prognose : {}
    }
    return votesData;
  }

  getPartyMandatesData(party) {
    let mandaterData = party.mandater ? party.mandater.resultat : {};
    if (this.isPrioritizeForecast()) {
      mandaterData = party.mandater ? party.mandater.prognose : {}
    }
    return mandaterData;
  }

  isPrioritizeForecast() {
    return this.data.prognose && this.data.prognose.beregnet == true
  }

  isIgnorePartyResult(party) {
    // "BLANKE" => The "BLANKE" party is the "party" that gets blank votes and this party must of course be treatede specially by all clients that use this API.
    // "Andre" => The party "Andre(Other)" is a summation / aggregation of all parties that have a party category 2 or 3, but not the "BLANKE".
    // Andre2" => The party "Andre2" are the parties that do not run in this election, but which ran in at least one of the two previous elections. This party always gets 0 in percent and will always have a decline in the changes from previous elections
    const partyCode = getPartyCode(party);
    return partyCode === "A" || partyCode === "Andre2" || partyCode === "BLANKE"
  }
}