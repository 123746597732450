const electionYear = process.env.ELECTION_YEAR;

export function getResultSiteUrl() {
  return process.env.RESULT_DATA_SITE_URL || (process.env.IS_DEV_MODE ? 'http://localhost:3000' : (`//` + window.location.host));
}

export function getAppDomainUrl() {
  let domain;
  if (document.currentScript) {
    const urlParts = document.currentScript.src.replace('http://', '').replace('https://', '').split(/[/?#]/);
    domain = urlParts[0];
  } else {
    domain = window.location.host;
  }

  return '//' + domain;
}

export function getWidgetElectionDataDomainUrl() {
  return process.env.RESULT_DATA_SITE_URL || process.env.IS_DEV_MODE ? getResultSiteUrl() : getAppDomainUrl();
}

export function getStyleSheetsDirectoryUrl() {
  return process.env.IS_DEV_MODE ? `${getAppDomainUrl()}/stylesheets` : `${getAppDomainUrl()}/widget/${electionYear}/stylesheets`;
}

export function getStaticAppUrl() {
  return process.env.IS_DEV_MODE ? `${getAppDomainUrl()}` : `${getAppDomainUrl()}/widget/${electionYear}`;
}

export function getCandidateImageById(id) {
  return `https://valgresultater.ntb.no/images/${electionYear}/candidates/${id}.jpg`;
}

export function getCandidateDetailsUrl(id) {
  return `${getStaticAppUrl()}/data/candidates/${id}.json`;
}

export function calculateAge(birthDate) {
  const monthDiff = Date.now() - new Date(birthDate).getTime();
  const ageDt = new Date(monthDiff);
  return Math.abs(ageDt.getUTCFullYear() - 1970);
}