import React, {useEffect, useRef, useState} from 'react';
import * as d3 from 'd3';
import classNames from './../../helpers/classnames';
import useWindowSize from '../../hooks/use-winow-size';

const StackedBar = React.memo((props) => {
  const { data, showMandates, showChange, showPercentageChange } = props;
  const containerRef = useRef(null);
  const barRef = useRef(null);
  const initalRender = useRef(true);
  const containerWidthTimer = useRef(null);
  const [containerWidth, setContainerWidth] = useState(0);
  const windowSize = useWindowSize();

  const margin = { top: 20, right: 10, bottom: 40, left: 10 };
  const width = (containerWidth > 320 ? (containerWidth >= 640 ? 700 : containerWidth - 4) : 316);
  const height = showChange ? 120 : 63;
  const barHeight = 35;

  let initialRender = true;

  const w = width - margin.left - margin.right
  const h = height - margin.top - margin.bottom
  const halfBarHeight = barHeight / 2

  const groupData = (data, total) => {
    const percent = d3.scaleLinear()
      .domain([0, total])
      .range([0, 100])
    let cumulative = 0
    return data.map(function (d) {
      const val = showMandates ? d.mandater : d.stemmer;
      cumulative += val
      return {
        value: val,
        // want the cumulative to prior value (start of rect)
        cumulative: cumulative - val,
        label: d.parti,
        name: d.name,
        color: d.color,
        percent: percent(val),
        mandatesChange: d.mandatesChange,
        mandater: d.mandater,
        percentageChange: d.percentageChange,
      }
    }).filter(function (d) {
      return d.value > 0
    })
  }

  const total = showMandates ? d3.sum(data, function (d) { return d.mandater; }) : d3.sum(data, function (d) { return d.stemmer; })
  const _data = groupData(data, total);

  const xScale = d3.scaleLinear().domain([0, total]).range([0, w])

  useEffect(() => {
    clearTimeout(containerWidthTimer.current);

    if (initalRender.current) {
      setContainerWidth(containerRef.current.offsetWidth);
      initalRender.current = false;
    } else {
      containerWidthTimer.current = setTimeout(() => {
        setContainerWidth(containerRef.current.offsetWidth);
      }, 100);
    }
  }, [windowSize?.width, initialRender]);

  useEffect(() => {
    d3.select(barRef.current).html("");
    const selection = d3.select(barRef.current)
      .append('svg')
      .attr('width', width)
      .attr('height', height)
      .append('g')
      .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')')

    const Tooltip = d3.select(barRef.current)
      .append("div")
      .style("opacity", 0)
      .attr("class", classNames('tooltip'))
      .style("background-color", "white")
      .style("border", "solid")
      .style("border-width", "2px")
      .style("border-radius", "5px")
      .style("padding", "5px")

    const mouseover = function (event, d) {
      Tooltip.style("opacity", 1);
      d3.select(`.rect-stacked[party-key=${d.label}]`)
        .style("stroke", "black")
        .style("opacity", 1)
    }

    const mousemove = function (event, d) {
      let name = d.name ?? d.label
      if (name === "A") {
        name = "Andre"
      }

      Tooltip
        .html(name + ": " + (showMandates ? d.value : parseFloat(d.value).toFixed(1).replace('.', ',')))
        .style("left", (d3.pointer(event)[0] + barRef.current.offsetLeft + 15) + "px")
        .style("top", (d3.pointer(event)[1] + barRef.current.offsetTop - 20) + "px")
    }

    const mouseleave = function (event, d) {
      Tooltip.style("opacity", 0)
      d3.select(`.rect-stacked[party-key=${d.label}]`)
        .style("stroke", "unset")
    }

    selection.selectAll('rect')
      .data(_data)
      .enter().append('rect')
      .attr('class', 'rect-stacked')
      .attr('party-key', (d) => d.label)
      .attr('x', function (d) { return xScale(d.cumulative) })
      .attr('y', h / 2 - halfBarHeight)
      .attr('height', barHeight)
      .attr('width', function (d) { return xScale(d.value) })
      // .attr('stroke', '#fff')
      .style('fill', function (d, i) { return d.color })
      .on("mouseover", mouseover)
      .on("mousemove", mousemove)
      .on("mouseleave", mouseleave)

    // add values on bar
    selection.selectAll('.text-value')
      .data(_data)
      .enter().append('text')
      .attr('class', 'text-value')
      .attr('text-anchor', 'middle')
      .attr('x', function (d) { return xScale(d.cumulative) + (xScale(d.value) / 2) })
      .attr('y', (h / 2) + 5)
      .style('fill', 'white')
      .style("font-size", "12px")
      .style("font-weight", "400")
      .on("mouseover", mouseover)
      .on("mousemove", mousemove)
      .on("mouseleave", mouseleave)
      .text(function (d) {
        // return (d.value >= 7 ? parseFloat(d.value).toFixed(1) : '') 
        return showMandates ? d.value : parseFloat(d.value).toFixed(1).replace('.', ',')
      })
      .each(function (d, i) {
        const labelWidth = this.getComputedTextLength();
        const percentage = parseFloat(d.value);
        const labelBarWidth = w * percentage / 100 - 2;

        if (labelWidth > labelBarWidth) {
          d3.select(this).node().remove()
        }
      })

    // add the labels
    // selection.selectAll('.stackbar__arrow')
    //   .data(_data)
    //   .enter()
    //   .append('image')
    //   .attr('x', function (d) { return xScale(d.cumulative) + (xScale(d.value) / 2) - 20/2 })
    //   .attr('y', (h / 2) - (halfBarHeight * 1.5) - 10 - 20/2)
    //   .attr('xlink:href', downArrow)
    //   .attr('width', 20)
    //   .attr('height', 20)

    selection.selectAll('.stackbar__textLabel')
      .data(_data)
      .enter()
      .append('text')
      .attr('class', 'stackbar__textLabel')
      .attr('font-size', '18')
      .attr('text-anchor', 'middle')
      .attr('x', function (d) { return xScale(d.cumulative) + (xScale(d.value) / 2) })
      .attr('y', (h / 2) + (halfBarHeight * 1.5) + 10)
      .style('fill', "#707070"/*(d, i) => d.color*/)
      .text(function (d) {
        return d.label;
      }).each(function (d, i) {
        var labelWidth = this.getComputedTextLength();
        var percentage = d.value;
        var labelBarWidth = w * percentage / 100 - 2;
        if (labelWidth > labelBarWidth) {
          d3.select(this).node().remove();
        }
      })

    // const labels = selection.selectAll('.stackbar__textLabel').nodes();

    if (showChange) {
      selection.selectAll('.stackbar__textLabel').each(function (d, i, nodes) {
        const currentLabel = d3.select(this);
        const currentLabelX = parseFloat(currentLabel.attr('x'));
        let posX1 = currentLabelX - currentLabel.node().getComputedTextLength() / 2 - 2;
        let posX2 = currentLabelX + currentLabel.node().getComputedTextLength() / 2 + 2;
        const posY = (parseFloat(currentLabel.attr('y')) + 15);

        if (i === 0) {
          const nextLabel = d3.select(nodes[1]);
          // const nextLabelX = parseFloat(nextLabel.attr('x'));
          posX1 = posX1 - 5;
        } else if (i < (nodes.length - 1)) {
          const previousLabel = d3.select(nodes[i - 1]);
          const nextLabel = d3.select(nodes[i + 1]);

          const previousLabelX = parseFloat(previousLabel.attr('x'));
          const nextLabelX = parseFloat(nextLabel.attr('x'));

          if ((currentLabelX - previousLabelX) > 30) {
            posX1 = posX1 - 10;
          }

          if ((nextLabelX - currentLabelX) > 30) {
            posX2 = posX2 + 10;
          }

        } else if (i === nodes.length) {
          // const previousLabel = d3.select(nodes[i - 1]);
        }

        if (d.percentageChange !== 0) {
          selection.insert('line')
          .attr('class', 'linestroke')
          .style('stroke', d.color)
          .style('stroke-width', 1)
          .attr('x1', posX1)
          .attr('y1', posY)
          .attr('x2', posX2)
          .attr('y2', posY)
        }

        if (showPercentageChange) {
          if (d.percentageChange !== 0) {
            selection.insert('text')
            .attr('class', 'counttext')
            .attr('text-anchor', 'middle')
            .attr('x', currentLabel.attr('x'))
            .attr('y', (parseFloat(currentLabel.attr('y')) + 32))
            // .text(function() {
            //   if (d.percentageChange == 0) {
            //     return '';
            //   } else {
            //     return d.percentageChange > 0 ? '+' + d.percentageChange : d.percentageChange;
            //   }
            // })
            .text(d.percentageChange >= 0 ? '+' + d.percentageChange : d.percentageChange)
          }
          
        } else {
          selection.insert('text')
          .attr('class', 'counttext')
          .attr('text-anchor', 'middle')
          .attr('x', currentLabel.attr('x'))
          .attr('y', (parseFloat(currentLabel.attr('y')) + 32))
          .text(d.mandatesChange >= 0 ? '+' + d.mandatesChange : d.mandatesChange)
        }
      });
    }
  }, [data, containerWidth, windowSize?.width]);

  return <div ref={containerRef} className={classNames('stackedbar__container')}>
    <div ref={barRef} />
  </div>;
});


export default StackedBar;