import React, { useContext } from 'react'
import classNames from '../helpers/classnames';
import { useTranslation } from 'react-i18next';
import { ConfigsContext } from '../context';

const Headlines = (props) => {
  let { title, location, timestamp } = props;
  let { voteCountPercentage } = props;
  const { t, i18n } = useTranslation('titles');
  const currentLocale = i18n.language;

  if (currentLocale === 'nn' && location === 'NORGE') {
    location = 'NOREG';
  }

  const configs = useContext(ConfigsContext);

  if (configs.voteCountText === 'false') {
    voteCountPercentage = 0;
  }

  return (
    <div className={classNames('headline__container')}>
      {location ? <div className={classNames(`headline__location`)}>{location}</div> : null}
      {(voteCountPercentage < 100 && timestamp) && (
        <div className={classNames(`headline__timestamp`)}>{timestamp}</div>
      )}
      <div className={classNames(`headline__voteCountPercentage`)}>
        {t('{{ percentage }}% AV STEMMENE OPPTALT', { percentage: (new Intl.NumberFormat("nb-NO")).format(voteCountPercentage) })}
      </div>
    </div>
  );
}

export default Headlines;