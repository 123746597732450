import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import classNames from './../../helpers/classnames';

const Bar = React.memo((props) => {
  const { historyData, currentData, historicHoverText, showChange } = props;

  const barRef = useRef(null);

  const sortedCurrentData = currentData.map((elm) => elm)
    .sort((a, b) => b.stemmer - a.stemmer);

  const topParty = sortedCurrentData[0]?.parti || '';

  const margin = { top: 25, right: 20, bottom: 55, left: 20 };
  const width = 360 - margin.left - margin.right;
  const height = 285 - margin.top - margin.bottom;

  const x = d3.scaleBand().range([0, width]);
  const y = d3.scaleLinear().range([height, 0]);

  useEffect(() => {
    d3.select(barRef.current).html("");

    const svg = d3.select(barRef.current)
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", "translate(5,45)");

    const g = svg.append("g").attr("transform", "translate(9," + margin.top + ")");
    const xAxis = d3.axisBottom(x).ticks(10);

    d3.axisRight(y)
      .tickSize(width)
      .ticks(4)
      .tickSizeOuter(0);

    const customXAxis = (g) => {
      const s = g.selection ? g.selection() : g;
      g.call(xAxis);

      g.select(".domain")
        .attr("stroke", "#fff")
        .attr("stroke-dasharray", "8,4")
        .attr("dy", 150)
        .attr('y', 150)
        .attr("transform", "translate(0,190)");

      s.selectAll(".tick line")
        .remove()

      s.selectAll(".tick text")
        // Shift text down if text is longer than 3 chars
        .attr("dy", (d) => d.length > 3 ? 205 : 185)
        .attr("dx", 8)
        .attr("style", "font-size:11px;color:#707070;");
    }

    const xGroup = g.append("g")
      .call(customXAxis);

    g.append("g")
      .attr("transform", "translate(0,-20)")

    d3.timeout(() => {
      xGroup.transition().duration(500).call(customXAxis);
    }, 1000);

    // scale the range of the data
    x.domain(currentData.map((d) => d.parti));
    y.domain([0, d3.max(currentData, (d) => d.stemmer)]);

    // create a tooltip
    const toolTip = d3.select(barRef.current)
      .append("div")
      .style("opacity", 0)
      .attr("class", classNames('tooltip'))
      .style("background-color", "white")
      .style("border", "solid")
      .style("border-width", "2px")
      .style("border-radius", "5px")
      .style("padding", "5px");

    const mouseover = function (d) {
      if (historicHoverText) {
        toolTip.style("opacity", 1);
        d3.select(this)
          .style("stroke", "black")
          .style("opacity", 0.4)
      }
    }

    const mousemove = function (event, d) {
      if (historicHoverText) {
        toolTip
          .html(historicHoverText)
          .style("left", (d3.pointer(event)[0] + barRef.current.offsetLeft + 15) + "px")
          .style("top", (d3.pointer(event)[1] + barRef.current.offsetTop) - 20 + "px")
      }
    }

    const mouseleave = function (d) {
      toolTip.style("opacity", 0)
      d3.select(this)
        .style("stroke", "none")
    }

    //lastElection data bar
    svg.selectAll(`.${classNames('bar__historyColumn')}`)
      .data(historyData)
      .enter()
      .append("rect")
      .attr("class", (d) => classNames(['bar__historyColumn', `bar__historyColumn__${d.parti.toLowerCase()}`]))
      .attr("fill", (d) => d.color)
      .attr("opacity", "0.2")
      .attr("x", (d) => x(d.parti) + 17)
      .attr("width", 18)
      .attr("y", (d) => y(0))
      .attr("height", 0)
      .attr("title", historicHoverText)
      .on("mouseover", mouseover)
      .on("mousemove", mousemove)
      .on("mouseleave", mouseleave)
      .transition()
      .duration(800)
      .attr("y", (d) => y(d.stemmer))
      .attr("height", (d) => height - y(d.stemmer))
      .delay((d, i) => (i * 100));

    // Add bar chart
    svg.selectAll(`.${classNames('bar__currentColumn')}`)
      .data(currentData)
      .enter()
      .append("rect")
      .attr("class", (d) => classNames(['bar__currentColumn', `bar__currentColumn__${d.parti.toLowerCase()}`]))
      .attr("fill", (d) => d.color)
      .attr("opacity", "1")
      .attr("x", (d) => x(d.parti) + 23)
      .attr("width", 18)
      .attr("y", (d) => y(0))
      .attr("height", 0)
      .transition()
      .duration(800)
      .attr("y", (d) => y(d.stemmer))
      .attr("height", (d) => height - y(d.stemmer))
      .delay((d, i) => (i * 100));

    svg.selectAll(`.${classNames('bar__percentage__value')}`)
      .data(currentData)
      .enter()
      .append("text")
      .attr("class", function (d) {
        let c = classNames('bar__percentage__value');
        if (d.parti === topParty) {
          c += ` ${classNames('bar__percentage__topParty')}`
        }
        return c;
      })
      .style("opacity", 0)
      .text((d) => parseFloat(d.stemmer).toFixed(1).replace('.', ','))
      .attr("x", (d) => (x(d.parti)) + 19)
      .attr("y", (d) => y(0))
      .transition()
      .duration(1500)
      .attr("y", (d) => (y(d.stemmer)) - 5)
      .style("opacity", 1)
      .delay((d, i) => (i * 100));

    if (showChange) {
      svg.selectAll()
        .data(currentData)
        .enter()
        .append("text")
        .attr("class", classNames('bar__directionArrow'))
        .style("opacity", 0)
        .text((d) => d.percentageChange === 0 ? "" : ">")
        .attr("rotate", function (d) {
          if (d.percentageChange > 0) {
            return -90;
          } else if (d.percentageChange === 0) {
            return 0;
          } else {
            return 90;
          }
        })
        .attr("x", function (d) {
          if (d.percentageChange > 0) {
            return ((x(d.parti)) + 28);
          } else if (d.percentageChange === 0) {
            return 0;
          } else {
            return ((x(d.parti)) + 11);
          }
        })
        .attr("dx", 7)
        .attr("y", function (d) { return y(0); })
        .transition()
        .duration(1500)
        .attr("y", function (d) {
          if (d.percentageChange > 0) {
            return (y(d.stemmer)) - 30;
          } else if (d.percentageChange === 0) {
            return 0;
          } else {
            return (y(d.stemmer)) - 30;
          }
        })
        .style("opacity", 1)

      svg.selectAll()
        .data(currentData)
        .enter()
        .append("text")
        .attr("class", classNames('bar__percentage__change'))
        .style("opacity", 0)
        .text(function (d) {
          if (d.percentageChange > 0) {
            return "+" + d.percentageChange.toFixed(1).replace('.', ',');
          } else if (d.percentageChange === 0) {
            return '';
          } else {
            return d.percentageChange.toFixed(1).replace('.', ',');
          }
        })
        .attr("x", function (d) { return (x(d.parti)) + 12; })
        .attr("y", function (d) { return y(0); })
        .transition()
        .duration(1500)
        .attr("y", function (d) {
          if (d.percentageChange >= 0) {
            return (y(d.stemmer)) - 17;
          } else {
            return (y(d.stemmer)) - 35;
          }
        })
        .style("opacity", 1)
    }

  }, [historyData, currentData]);

  return (
    <div ref={barRef} />
  )
});

export default Bar;