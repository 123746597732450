import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import CandidateService from '../../services/candidate';
import classNames from '../../helpers/classnames';
import LoaderInline from '../common/loader-inline';
import { ConfigsContext } from '../../context';
import { getCandidateImageById } from '../../helpers/utils';
import femaleProfileImage from './../../assets/images/candidate-female-profile.png'
import maleProfileImage from './../../assets/images/candidate-male-profile.png';
import CloseButtonIcon from './../../assets/images/close-button.svg';

const CandidateDetailsModal = (props) => {
  const { candidateId, onClose, representative, newRepresentativeCount = 0, kandidateApi } = props;
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const configs = useContext(ConfigsContext);
  const { t, i18n } = useTranslation('popups');
  const currentLocale = i18n.language;

  const candidateService = new CandidateService();

  const candidatesDataUrl = configs.candidatesDataUrl;

  const candidateFileUrl = `${candidatesDataUrl}/${candidateId}.json`;

  useEffect(() => {
    candidateService.getCandidateByFileUrl(candidateFileUrl)
      .then(data => {
        setProfile(data)
        setLoading(false);
      });
    // if (kandidateApi) {
    //   candidateService.getCandidateById(candidateId)
    //   .then(data => {
    //     setProfile(data);
    //     setLoading(false);
    //   })
    // }
  }, []);

  if (!candidateId) {
    return null;
  }

  const isNew = representative?.isNew;
  const age = representative?.person?.alder || profile?.age;
  const name = representative?.person?.navn || profile.name;
  const placeNumber = representative ? (
    t('{{ placeNumber }}. kandidat i {{ district }}', {
      placeNumber: representative.plassnummer,
      district: representative.district.name
    })) : '';

  let defaultImage;
  let profileImage = defaultImage =  representative?.person?.kjonn == 'M' ? maleProfileImage : femaleProfileImage;
  
  const getDefaultPicture = (ev) => {
    ev.target.src = defaultImage;
  }

  if (candidateId) {
    profileImage = getCandidateImageById(candidateId)
  }

  let partyShortCode = profile?.linedUpForPartyCode || representative?.party?.partyCode;
  let partyShortCodeLabel = partyShortCode;
  if (profile) {
    switch (partyShortCode) {
      case 'A':
        partyShortCode = 'AP';
        break;
      case 'RØDT':
        partyShortCode = 'R';
        break;
      case 'And':
        partyShortCode = 'A';
        partyShortCodeLabel = representative?.party?.identity?.partikode || partyShortCode
    }
  }

  return (
    <div className={classNames('modal__candidate__wrapper')}>
      <div className={classNames('modal__candidate__container')}>
        {loading ? (
          <div className={classNames('modal__loader')}>
            <LoaderInline loading={true}/>
          </div>
        ) : (
          <div className={classNames('modal__candidate__content')}>
            { isNew && (
              <>
                <h2 className={classNames('modal__candidate__title')}>
                  {t('Er en av {{ countNewPresentatives }} nye representanter', {countNewPresentatives: newRepresentativeCount })}
                </h2>
                <h3 className={classNames('modal__candidate__situation')}>{t('NY PÅ STORTINGET')}</h3>
              </>
            )}
            <div className={classNames('modal__candidate__picture')}>
              <img
                src={profileImage}
                onError={getDefaultPicture}
              />
              <div className={classNames(['modal__candidate__party__circle', `modal__candidate__party__circle--${partyShortCode.toLowerCase()}`])}>
                {partyShortCodeLabel}
              </div>
            </div>
            <div className={classNames('modal__candidate__bio__location')}>
              <div className={classNames('modal__candidate__bio')}>
                <span className={classNames('modal__candidate__name')}>{name}</span>
                {age && age > 0 && <span className={classNames('modal__candidate__age')}> ({t('{{ age }} år', { age: age })})</span>}
              </div>
              {profile && profile.lineUps.length > 0 && profile.lineUps.map((item) => (
                <div className={classNames('modal__candidate__place__location')} key={item.lineUpName}>
                  {t('{{ placeNumber }}. kandidat i {{ district }}', {placeNumber: item.listPriorityNumber, district: item.lineUpName})}
                </div>
              ))}
              {(!profile || (profile && profile.lineUps.length == 0)) && (
                <div className={classNames('modal__candidate__place__location')}>
                  {placeNumber}
                </div>
              )}
            </div>
            { currentLocale === 'nb' && profile?.politicsInterest && (
              <>
                <div className={classNames('modal__candidate__oath')}>{t('Jeg ble politiker fordi…')}</div>
                <div className={classNames('modal__candidate__commitment')}>{profile.politicsInterest}</div>
              </>
            )}
            
          </div>
        )}
        <button className={classNames('modal__close__button')}><CloseButtonIcon onClick={onClose}/></button>
      </div>
    </div>
  )
}


export default CandidateDetailsModal;