import React, {useEffect, useMemo, useState} from "react"
import {createPortal} from 'react-dom';
import classNames from "../../helpers/classnames";
import {Trans, useTranslation} from "react-i18next";
import EditIcon from "../../assets/images/edit-icon.svg";
import Line from "../charts/line";
import ShowMoreIcon from "../../assets/images/show-more-icon.svg";
import FocusLock from "react-focus-lock";
import max from "lodash-es/max";
import take from "lodash-es/take";

export default function HistoricalDataChart({data, valgSwitch}) {
  const {t} = useTranslation()

  const parties = [
    {key: "AP", name: "Arbeiderpartiet"},
    {key: "H", name: "Høyre"},
    {key: "FRP", name: "Fremskrittspartiet"},
    {key: "SV", name: "Sosialistisk Venstreparti"},
    {key: "SP", name: "Senterpartiet"},
    {key: "KRF", name: "Kristelig Folkeparti"},
    {key: "V", name: "Venstre"},
    {key: "MDG", name: "Miljøpartiet De Grønne"},
    {key: "R", name: "Rødt"},
    {key: "ANDRE", name: "Andre"},
  ]

  const formattedData = useMemo(() => {
    // Merge all parties not in `parties` into the "Andre" category instead
    let chartData = []
    Object.keys(data || {}).forEach(year => {
      const yearData = data[year]

      if (yearData) {
        // Change Arbeiderpartiet's partyCode
        if (yearData['A']) {
          data[year]['AP'] = data[year]['A']
          data[year]['AP'].partikode = 'AP'
          delete data[year]['A']
        }

        // Change Rødt's partyCode
        if (yearData['RØDT']) {
          data[year]['R'] = data[year]['RØDT']
          data[year]['R'].partikode = 'R'
          delete data[year]['RØDT']
        }

        // Merge all other parties into "Andre"
        const otherValue = Object.keys(yearData)
          .filter(x => !parties.map(party => party.key).includes(x))
          .map(x => yearData[x].data)
          .reduce((prev, cur) => prev + cur)

        data[year]['ANDRE'] = {data: otherValue, navn: 'Andre', partikode: 'ANDRE'}
      } else {
        delete data[year]
      }
    })

    // Prepare the data for consumption by our line graph
    chartData = Object.keys(data || {}).flatMap(year => {
      return Object.keys(data[year] || {}).flatMap(partyKey => {
        const values = data[year][partyKey]

        return {
          label: partyKey,
          year,
          value: values.data,
          name: values.navn
        }
      })
    })

    // Make sure the data only includes the official parties + "Andre"
    return chartData.filter(x => parties.map(party => party.key).includes(x.label))
  }, [data])

  const [partySelectorOpen, setPartySelectorOpen] = useState(false)
  const [selectedParties, setSelectedParties] = useState([])

  // Find top four parties for the latest year and display them by default in `selectedParties`
  useEffect(() => {
    if (!formattedData || !formattedData.length) return null;

    const maxYear = max(formattedData.map(x => x.year))
    const topFourParties = take(
      formattedData
        .filter(x => x.label !== "ANDRE")
        .filter(x => x.year === maxYear)
        .sort((a, b) => b.value - a.value),
      4)
      .map(x => x.label)

    setSelectedParties(topFourParties)
  }, [formattedData])

  const onSelectedPartyChange = (party) => {
    const checked = selectedParties.includes(party.key)

    if (!checked) {
      setSelectedParties([...selectedParties, party.key])
    } else {
      setSelectedParties(selectedParties.filter(x => x !== party.key))
    }
  }

  return <div className={classNames('historical-data')}>
    <div className={classNames('historical-data__container')}>
      <div className={classNames('historical-data__container__inner')}>
        <h2 className={classNames('historical-data__container__inner__header')}>
          {valgSwitch === "KO" && <Trans i18nKey={`charts:historic:SISTE KOMMUNEVALG`} t={t}/>}
          {valgSwitch === "FY" && <Trans i18nKey={`charts:historic:SISTE FYLKESTINGSVALG`} t={t}/>}
        </h2>

        <button className={classNames('historical-data__container__inner__select-parties')}
                onClick={() => setPartySelectorOpen(true)}>
          <EditIcon className={classNames('historical-data__container__inner__select-parties__icon')}/>

          <div className={classNames('historical-data__container__inner__select-parties__text')}>
            {<Trans i18nKey={`charts:historic:VELG PARTIER`} t={t}/>}
          </div>
        </button>

        <div className={classNames('historical-data__container__inner__graph')}>
          <Line chartData={formattedData} selectedParties={selectedParties}/>
        </div>

        <div className={classNames('historical-data__container__inner__disclaimer')}>
          Regionendringer etter ny kommune- og fylkesinndeling kan føre til unøyaktigheter i det historiske datagrunnlaget.
        </div>
      </div>
    </div>

    {createPortal(
      <>
        {partySelectorOpen && <FocusLock>
          <div className={classNames("historical-data")} aria-modal="true" onClick={() => setPartySelectorOpen(false)}>
            <div className={classNames("historical-data__parties")}>
              <div className={classNames("historical-data__parties__container")}>
                <div className={classNames("historical-data__parties__container__inner")} onClick={(e) => e.stopPropagation()}>
                  <div className={classNames("historical-data__parties__container__inner__items")}>
                    {parties.map(party => <div key={party.key}
                                               className={classNames("historical-data__parties__container__inner__items__item")}>
                      <input type="checkbox" id={party.key} defaultChecked={selectedParties.includes(party.key)} onChange={() => onSelectedPartyChange(party)}/>
                      <label htmlFor={party.key}>
                        {party.name}
                      </label>
                    </div>)}
                  </div>
                  <button className={classNames("historical-data__parties__container__inner__close")}
                          onClick={() => setPartySelectorOpen(false)} aria-label={"Lukk og lagre valg av partier"}>
                    <ShowMoreIcon/>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </FocusLock>}
      </>, document.querySelector(".ntbValgResult"))}
  </div>
}