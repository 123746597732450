import React, { useLayoutEffect, useRef } from 'react'
import * as d3 from 'd3';
import { useTranslation } from 'react-i18next';
import classNames from './../../helpers/classnames'
import {isNationWideAreaId} from "../../helpers/charts-helper";

const Pie = React.memo(({ data, valgSwitch, showChange, areaId }) => {
  // const localParties = [ 'R', 'SV', 'AP', 'SP', 'MDG', 'V', 'KRF', 'H', 'FRP', 'A'];
  //
  // const otherParties = data.filter(d => !localParties.includes(d.parti));
  // data = data.filter(d => localParties.includes(d.parti));
  //
  // const andrePartyIndex = data.findIndex(d => d.parti === 'A');
  //
  // if (andrePartyIndex !== -1) {
  //   otherParties.forEach(dataEntry => {
  //     data[andrePartyIndex].mandater = ((data[andrePartyIndex].mandater || 0) + dataEntry.mandater);
  //     data[andrePartyIndex].value = ((data[andrePartyIndex].value || 0) + dataEntry.value);
  //   });
  // }

  const totalM = d3.sum(data, (d) => d.value)

  const { t } = useTranslation();

  if (totalM === 0) {
    return null
  }

  if (data.length === 0) {
    return null
  }

  data = data.filter(i => i.value !== 0)

  const pieRef = useRef(null)
  const innerWidth = 300
  const outerWidth = 360
  const height = 300
  const radius = Math.min(innerWidth, innerWidth) / 2.3

  const transformTop = 210
  const transformLeft = outerWidth / 2

  const arc = d3.arc().innerRadius(radius - 22).outerRadius(radius - 8)

  useLayoutEffect(() => {
    d3.select(pieRef.current).html('')
    const pie = d3.pie()
      .value((d) => d.value)
      .sort(null)
      .startAngle(-0.5 * Math.PI)
      .endAngle(0.5 * Math.PI)

    // tooltips
    const Tooltip = d3.select(pieRef.current)
      .append('div')
      .style('opacity', 0)
      .attr('class', classNames('tooltip'))
      .style('background-color', 'white')
      .style('border', 'solid')
      .style('border-width', '2px')
      .style('border-radius', '5px')
      .style('padding', '5px')

    const mouseover = () => {
      Tooltip.style('opacity', 1)
      d3.select(this)
        .style('stroke', 'black')
        .style('opacity', 1)
    }

    const mousemove = (event, d) => {
      let name = d.data.name ?? d.data.label
      if (name === "A") {
        name = "Andre"
      }

      Tooltip
        .html(`${name}: ${d.value} ${showChange ? `(${d.data.mandatesChange > 0 ? '+' : ''}${d.data.mandatesChange})` : ''}`)
        .style('left', ((d3.pointer(event)[0] + pieRef.current.offsetLeft + 15) + transformLeft) + 'px')
        .style('top', ((d3.pointer(event)[1] + pieRef.current.offsetTop - 20) + transformTop) + 'px')
    }

    const mouseleave = () => {
      Tooltip.style('opacity', 0)
      d3.select(this)
        .style('stroke', 'white')
    }

    let infoText = ''

    if (valgSwitch === "RST") {
      infoText = t('charts:mandates:{{ totalMandates }} MANDATER PÅ STORTINGET', {totalMandates: totalM})
    }

    const isNationWide = isNationWideAreaId(areaId)

    if (isNationWide) {
      switch (valgSwitch) {
        case 'KO':
          infoText = t('charts:mandates:{{ totalRepresentatives }} repr. i kommunestyrer', {totalRepresentatives: totalM})
          break
        case 'FY':
          infoText = t('charts:mandates:{{ totalRepresentatives }} repr. i fylkesting', {totalRepresentatives: totalM})
          break
      }
    } else {
      switch (valgSwitch) {
        case 'KO':
          infoText = t('charts:mandates:{{ totalRepresentatives }} repr. i kommunestyret', {totalRepresentatives: totalM})
          break
        case 'FY':
          infoText = t('charts:mandates:{{ totalRepresentatives }} repr. i fylkestinget', {totalRepresentatives: totalM})
          break
      }
    }

    const svg = d3.select(pieRef.current)
      .append('svg')
      .attr('width', outerWidth)
      .attr('height', height)
      .append('g')
      // .attr("transform", "translate(" + width / 2 + "," + height / 2 + ")")
      .attr('transform', `translate(${transformLeft}, ${transformTop})`)

    svg.datum(data)
      .selectAll(`path.${classNames('pie__Slice')}`)
      .data(pie)
      .enter()
      .append('path')
      .attr('fill', (d, i) => data[i].color) /*color[i]*/
      .attr('class', (d) => classNames('pie__Slice', `pie__Slice--${d.data.label}`))
      .on('mouseover', mouseover)
      .on('mousemove', mousemove)
      .on('mouseleave', mouseleave)
      .transition()
      .duration(1000)
      .attrTween('d', (b) => {
        const i = d3.interpolate(
          { startAngle: -90 * (Math.PI / 180), endAngle: -90 * (Math.PI / 180) },
          b
        )

        return (t) => arc(i(t))
      })
      .ease()

    // lines and info text
    svg.append('line')
      .attr('stroke-width', 1)
      .attr('stroke', '#707070')
      .attr('stroke-dasharray', '1,2')
      .attr('class', classNames('pie__topline'))
      .attr('x1', 0)
      .attr('x2', 0)
      .attr('y1', -90)
      .attr('y2', -130)

    svg.append('line')
      .attr('stroke-width', 1)
      .attr('stroke', '#707070')
      .attr('stroke-dasharray', '1,2')
      .attr('class', classNames('pie__bottomline'))
      .attr('x1', 0)
      .attr('x2', 0)
      .attr('y1', -72)
      .attr('y2', -35)

    svg.append('text')
      .attr('text-anchor', 'middle')
      .attr('class', classNames('pie__dottedInfotext'))
      .attr('fill', '#707070')
      .text('FLERTALL')
      .attr('y', -77)
      .attr('x', 0)
      .attr('font-size', 10)

    svg.append('text')
      .attr('text-anchor', 'middle')
      .attr('class', classNames('pie__bottomInfotext'))
      .attr('fill', '#707070')
      .text(infoText)
      .attr('font-size', 15)
      .attr('y', 35)
      .attr('x', 0)

    const labels = svg.append('g')

    const enteringLabels = labels.selectAll(`.${classNames('pie__label')}`).data(pie(data)).enter()
    const labelGroups = enteringLabels.append('g').attr('class', classNames('pie__label'))

    const radianToDegree = (angle) => (angle * 180) / Math.PI

    const getCentroidAngles = (d, arc) => {
      const centroid = arc.centroid(d);
      const [centroidX, centroidY] = centroid;

      // In the axis, x is positive to the right of the center horizontally and y is positive to the bottom of the center vertically
      // The angle between the axis and centroid coordinate. It is minus in this case. Because arc y coordinate is negative upward from origin
      const centroidAngle = Math.atan2(centroidY, centroidX);
      const centroidAngleInDegree = radianToDegree(centroidAngle);

      const arcAngle = d.endAngle - d.startAngle;

      return {
        centroid: {x: centroidX, y: centroidY},
        centroidAngleInDegree,
        arcAngle
      }
    }

    const outerArc = d3.arc().innerRadius(radius - 22).outerRadius(radius + 70)

    let textLabels = labelGroups
      .append('text')
      .attr('text-anchor', 'middle')
      .attr('class', classNames('pie__labelText'))
      .attr('transform', function (d) {
        const c = outerArc.centroid(d)

        const { centroidAngleInDegree } = getCentroidAngles(d, outerArc)
        let rotationAngle = centroidAngleInDegree

        if (rotationAngle <= -90) {
          rotationAngle = rotationAngle + (360 / 2)
        }

        const x = c[0]
        const y = c[1]

        return 'translate(' + x + ',' + y + ') rotate('+ rotationAngle +')'
      })
      .attr('font-size', 14)
      // Hide parties with less than 2 % of the votes, and hide 'A'
      .each(function (d) {
        const percentage = (d.value / totalM) * 100
        if (percentage < 2 || d.data.label === 'A') {
          d3.select(this).node().remove()
        }
      })

    textLabels.append('tspan')
      .attr('class', classNames('pie__partyTitle'))
      .text((d) => d.data.label)
      .attr('dy', '4px')

    textLabels.append('tspan')
      .attr('class', classNames('pie__candidateCount'))
      .text((d) => d.value)
      .attr('dx', '4px')

    if (showChange) {
      textLabels.append('tspan')
        .attr('class', classNames('pie__mandateChange'))
        .text((d) => d.data.mandatesChange === 0 ? '-' : `${d.data.mandatesChange > 0 ? '+' : ''}${d.data.mandatesChange}`)
        .attr('dx', function () {
          const labelWidth = this.getComputedTextLength()
          return -labelWidth
        })
        .attr('dy', '-2.4em')}
  }, [data])

  return <div className={classNames('pie')}>
    <div ref={pieRef}/>
  </div>
})

export default Pie