import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { usePopperTooltip } from 'react-popper-tooltip';
 
const Tooltip = ({children, tooltip, placement='bottom-start', hideArrow, ...props}) => {
  const [ controlledVisible, setControlledVisible ] = useState(false);
  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip({
    visible: controlledVisible,
    onVisibleChange: setControlledVisible,
    trigger: 'click',
    closeOnOutsideClick: true,
    // interactive: true,
    delayHide: 500,
    placement: 'bottom-start'
  });

  const close = () => setControlledVisible(false);

  return (
    <>
      {children && typeof children === 'function'
        ? children(setTriggerRef)
        : (<span ref={setTriggerRef} >{children}</span>)
      }
      {visible && (
        ReactDOM.createPortal(
        <div
          ref={setTooltipRef}
          {...getTooltipProps({ className: 'tooltip-top-container ntbValgResult' })}
        >
          <div className="tooltip-inner-container">
            {/* <button className="close-button" onClick={() => setControlledVisible(false)}></button> */}
            { !hideArrow && <div {...getArrowProps({ className: 'tooltip-arrow', 'data-placement': placement })} /> }
            {tooltip && typeof tooltip === 'function'
            ? tooltip(close)
            : tooltip}
          </div>
        </div>
        ,
        document.body
      )
      )}
    </>
  );
};
 
export default Tooltip;