import { formatPartyCode } from './charts-helper';
import { calculateAge } from './utils';

export function getSearchEntries(data) {
  const searchItems = [];

  const counties = data["fylker"];

  searchItems.push({
    name: "Norge (Fylker)",
    filePostfix: "00-FY",
    title: "Norge",
    type: 'county',
  });

  searchItems.push({
    name: "Norge (Kommuner)",
    filePostfix: "00-KO",
    title: "Norge",
    type: 'municipality',
  });

  for (const county of counties) {
    const countyName = county["fylke"];
    const countyNumber = county["fylkesnummer"];
    const countyFilePostfix = countyNumber;

    const countyNameExtension = `fylke`;

    searchItems.push({
      name: countyName,
      filePostfix: countyFilePostfix,
      title: `${countyName} - ${countyNameExtension}`,
      nameExtension: countyNameExtension,
      type: 'county',
    });

    const municipalities = county["kommuner"];

    if (municipalities && municipalities.length > 0) {
      for (const municipality of municipalities) {
        const municipalityName = municipality["kommune"];
        const municipalityNumber = municipality["kommunenummer"];
        const municipalityFilePostfix = `${countyFilePostfix}-${municipalityNumber}`;

        const municipalityNameExtension = `kommune i ${countyName}`;

        searchItems.push({
          name: municipalityName,
          filePostfix: municipalityFilePostfix,
          title: `${municipalityName} - ${municipalityNameExtension}`,
          nameExtension: municipalityNameExtension,
          type: 'municipality',
        });

        const krets = municipality["kretser"]; // voting circuits

        if (krets && krets.length > 0) {
          for (const kret of krets) {
            const kretNumber = kret["kretsnummer"];
            const isAdvancedVote = kretNumber === '0000';
            const kretName = kret["kretsnavn"];
            const kretFilePostfix = `${municipalityFilePostfix}-${kretNumber}`;

            const kretNameExtension = `krets i ${municipalityName}`;

            // const kretNameExtension = isAdvancedVote ? `Forhåndsstemmer i ${municipalityName}` : `krets i ${municipalityName}`;

            searchItems.push({
              name: isAdvancedVote ? `Forhåndsstemmer i ${municipalityName}` :  kretName,
              filePostfix: kretFilePostfix,
              title: isAdvancedVote ? `Forhåndsstemmer i ${municipalityName}` : `${kretName} - ${kretNameExtension}`,
              nameExtension: isAdvancedVote ? null : kretNameExtension,
              type: 'kret',
            });
          }
        }

        const bydeler = municipality["bydeler"]; // city suburbs, only for Oslo

        if (bydeler && bydeler.length > 0) {
          for (const bydel of bydeler) {
            const bydelNumber = bydel["kretsnummer"];
            const bydelName = bydel["kretsnavn"];
            const bydelFilePostfix = `${municipalityFilePostfix}-${bydelNumber}`;

            const bydelNameExtension = `bydel i ${municipalityName}`;

            searchItems.push({
              name: bydelName,
              filePostfix: bydelFilePostfix,
              title: `${bydelName} - ${bydelNameExtension}`,
              nameExtension: bydelNameExtension,
              type: 'bydel',
            });
          }
        }
      }
    }
  }

  return searchItems;
}

export function getCandidateSearchEntries(data) {
  const representatives = [];

  for (const district of data.valgdistrikter) {
    const parties = district.partier;
    const districtNumber = district.valgdistrikt;
    const districtMandates = district.antall_mandater;
    const districtName = district.navn;

    const districtData = {
      id: districtNumber,
      name: districtName,
      totalMandates: districtMandates,
    }

    for (const party of parties) {
      const partyCode = party.partikode;
      const partyName = party.partinavn;
      const partyCategory = party.partikategori;

      const partyData = {
        partyCode: formatPartyCode(partyCode),
        identity: {
          navn: partyName,
          partikategori: partyCategory,
          partyCode
        }
      }

      for (const candidate of party.kandidater) {
        const personName = candidate.navn;
        const placeNumber = candidate.plassnummer;
        const birthDate = candidate.fødselsdato;
        const gender = candidate.kjønn;
        const altingetId = candidate.id;

        const personData = {
          alder: calculateAge(birthDate),
          kjonn: gender,
          navn: personName,
        }

        representatives.push({
          district: districtData,
          party: partyData,
          person: personData,
          altingetId: altingetId,
          id: altingetId,
          plassnummer: placeNumber,
        })
      }
    }
  }

  return representatives;
}