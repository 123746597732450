import './../../assets/sass/app.scss';
import React from 'react';
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import qs from 'qs';
import {getResultSiteUrl, getStyleSheetsDirectoryUrl} from './../../helpers/utils';
import Main from './main';

const electionYear = process.env.ELECTION_YEAR;

Modal.setAppElement('#app');

const App = () => {
  const configs = qs.parse(window.location.search, { ignoreQueryPrefix: true });
  configs.domainUrl = getResultSiteUrl();
  configs.styleSheetUrl = getStyleSheetsDirectoryUrl();
  configs.chartDataUrl = configs.domainUrl + `/json/${electionYear}`;
  configs.tickerDataUrl = configs.domainUrl + `/robot/${electionYear}`;

  return (
    <Main configs={configs}/>
  );
}

ReactDOM.render(
  <App />,
  document.getElementById('app')
);