import React, { useContext } from 'react';
import { css } from "@emotion/react";
import FadeLoader from "react-spinners/FadeLoader";
import classNames from '../../helpers/classnames';
import { ConfigsContext } from '../../context';

const Loader = (props) => {
  const { loading } = props;

  const configs = useContext(ConfigsContext);

  if (!loading) { return null; }

  return (
    <div className={classNames('loading')}>
      <FadeLoader
        color={configs.loaderColor || '#35CBB9'}
        {...props}
        loading={true}
      />
    </div>
  )
}

export default Loader;