import React, { useEffect, useRef, useState, useContext } from 'react';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';
import classNames from '../helpers/classnames';
import { ConfigsContext } from '../context';
import CandidateService from '../services/candidate';
import LoaderInline from './common/loader-inline';
import CandidateDetailsModal from './modals/candidate-details-modal';
import Tooltip from './popups/tooltip';
import { getElectionDistricts } from './../helpers/charts-helper'

const Search = (props) => {
  const { title, searchItems, searchCandidates, onChangeAreaId, areaId } = props
  const [modalIsOpen, setIsOpen] = useState(false);
  const [remoteSearch, setRemoteSearch] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [filteredSearchItems, setFilteredSearchItems] = useState([]);
  const searchFilterRef = useRef(null);
  const searchInputRef = useRef(null);
  const districtsIdsRef = useRef(Object.keys(getElectionDistricts()))
  const searchItemEverClicked = useRef(false);
  const configs = useContext(ConfigsContext);

  const { t } = useTranslation('forms');

  const candidateService = new CandidateService();

  const onItemClick = (item) => {
    const newAreaId = item.filePostfix;
    searchItemEverClicked.current = true;

    if (newAreaId) {
    // if (newAreaId && newAreaId.indexOf(configs.areaId) === 0) {
      onChangeAreaId(newAreaId);
      setIsOpen(false);
    } else {
      // show candidate popup
    }
  }

  function handleSearchTexChange(event) {
    const value = event.target.value;
    setSearchText(value)
  }

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    searchInputRef.current?.focus();
  }

  function closeModal() {
    if (!searchText && searchItemEverClicked.current) {
      onChangeAreaId(configs.areaId || '');
    }

    setIsOpen(false);
  }

  useEffect(() => {
    if (searchFilterRef.current) {
      setRemoteSearch(false);
      clearTimeout(searchFilterRef.current);
    }
    searchFilterRef.current = setTimeout(async () => {
      const configAreaId = configs.areaId;
      let districtId = '';

      if (configAreaId)  {
        districtId = configAreaId.split('-')[0];
        if (!districtsIdsRef.current.includes(districtId)) {
          districtId = '';
        }
      }

      let items = searchText ? searchItems.filter(item => {
        return item.title.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
        // if (districtId) {
        //   return item.filePostfix.indexOf(configAreaId) === 0 && item.title.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
        // } else {
        //   return item.title.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
        // }
      }) : [];

      // if (searchText.length > 3) {
      //   setRemoteSearch(true);
      //   const candidates = await candidateService.searchCandidates(searchText);
      //   if (candidates.length > 0) {
      //     items = candidates.concat(items);
      //   }
      //   setRemoteSearch(false);
      // }
      // if (searchText.length > 2) {
      //   const candidates = searchText ? searchCandidates.filter(representative => {
      //     return representative.person.navn.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
      //
      //     // if (districtId) {
      //     //   return representative.district.id === districtId && representative.person.navn.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
      //     // } else {
      //     //   return representative.person.navn.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
      //     // }
      //
      //   }) : []
      //   items = candidates.concat(items);
      // }

      setFilteredSearchItems(items);

    }, 700);

    return () => {
      clearTimeout(searchFilterRef.current);
    }
  }, [searchItems, searchCandidates, searchText]);

  return (
    <>
      <div className={classNames('search__buttonContainer')}>
        <button className={classNames('search__button')} onClick={openModal}>
          <div className={classNames('search__icon')} />
          {title}
        </button>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        contentLabel="Country/Municipality search modal"
        portalClassName={'ntbValgResult'}
        closeTimeoutMS={300}
        shouldCloseOnOverlayClick={true}
        bodyOpenClassName={classNames('search__ReactModal__Body--open')}
        className={classNames('search__modal__content')}
        overlayClassName={classNames('search__modal__overlay')}
      >
        <div className={classNames('search__modal__content__container')}>
          <div className={classNames('search__formContainer')}>
            <div className={classNames('search__icon', 'search__icon--tighter')} />
            <input
              ref={searchInputRef}
              // autoFocus
              className={classNames('search__input')}
              type="text" name="s"
              placeholder={t(`Skriv inn fylke, kommune eller krets`)}
              value={searchText}
              onChange={handleSearchTexChange}
            />
            <button className={classNames('search__buttonClose')} onClick={closeModal}>X</button>
          </div>
          {remoteSearch && (
            <div className={classNames('search__loader__container')}>
              <LoaderInline loading={remoteSearch} height={10} radius={1} />
            </div>
          )}

          {filteredSearchItems.length > 0 ? (
            <div className={classNames('search__result__container')}>
              <ul className={classNames('search__result__listGroup')}>

                {filteredSearchItems.map((item, index) => {
                  const renderButton = () => (
                    <button className={`${item.filePostfix === areaId ? 'selected' : ''}`} onClick={() => onItemClick(item)}>
                        <span>
                          {item.nameExtension ? (
                            <>{item.name} - <i>{item.nameExtension}</i></>
                          ) : item.name || item.person.navn}
                        </span>
                        <span>
                          {item.nameExtension ? (
                            <>{item.name} - <i>{item.nameExtension}</i></>
                          ) : item.name || item.person.navn}
                        </span>
                      </button>
                  )

                  return (
                    <li key={item.filePostfix || (`${item.id}-${item.name}-${item.party?.partyCode}-${item.district?.id}`)}>
                      { item.id ? (
                        <Tooltip hideArrow={true}
                          placement="right-start"
                          trigger="click"
                          tooltip={(close) => <CandidateDetailsModal representative={item} kandidateApi={configs.kandidateApi !=='false' } candidateId={item.id} onClose={close} />}
                        >
                          {renderButton()}
                        </Tooltip>
                      ) : renderButton()}

                      {/* {renderButton()} */}
                    </li>
                  )
                })}
              </ul>

              <div className={classNames("search__result__container__border")} />
            </div>
          ) : null}
        </div>
      </Modal>
    </>
  );
}

export default Search;